import client from '../Client'

// Constants
const initial = {
    list: [],
    row: {},
    seriesList: [],
    searchCond: {}
}

// Types
const MODULE_NAME = "MS02_PROUDCT_POPUP_"
const LIST =  MODULE_NAME + "LIST"
const ROW = MODULE_NAME + "ROW"
const SERIES_LIST = MODULE_NAME + "SERIES_LIST"
const INITIAL = MODULE_NAME + "INITIAL"
const SEARCH_COND = MODULE_NAME + "SEARCH_COND"

// Reducer
export default function productPopupReducer(state = initial, action){
    switch(action.type){
        case LIST:
            return {...state, list: action.payload, row: {}, error: null}
        case ROW:
            return {...state, row: action.payload, error: null}
        case SEARCH_COND:
            return {...state, searchCond: action.payload}
        case SERIES_LIST:
            return {...state, seriesList: action.payload}
        case INITIAL:
            return initial
        default:
            return state
    }
}

// Actions
export const Init = () => async (dispatch, getState) => {
    client.postData([{api: "m0040SeriesService.findSeries"}], dispatch)
        .then((data) => {
            dispatch({
                type: SERIES_LIST,
                payload: data[0].output
            })
        }
    )
} 

export const Search = (searchCond) => async (dispatch, getState) => {
    dispatch({
        type: SEARCH_COND,
        payload: searchCond
    })
    client.postData([{api: "m0020ProductService.searchIncludingOnline", input: searchCond }], dispatch)
        .then((data) => {
            dispatch({
                type: LIST,
                payload: data[0].output
            })
        }
    )
}
