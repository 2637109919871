import { makeStyles, Paper } from "@material-ui/core";

const PaperCom = (props) => {
  const padding = props.p ?? 5;
  const margin = props.m ?? 0;
  
  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(padding),
      margin: theme.spacing(margin),
    },
  }));
  const classes = useStyles();

  return (
    <Paper className={classes.paper} {...props}>
      {props.children}
    </Paper>
  );
};

export default PaperCom;
