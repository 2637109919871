import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import authReducer from './useAuthRedux'
import dataReducer from './useDataRedux'
import commonReducer from './CommonReducer'
import noticeReducer from './Demo/useNoticeRedux'
import productReducer from './Demo/useProductRedux'
import userPopupRedux from './Demo/useUserPopupRedux'
import productPopupRedux from './Demo/useProductPopupRedux'
import videoInfo from './getVideoRedux'
import tagInfo from "./getTagRedux"
import userInfo from "./getUserRedux"

const rootReducer = combineReducers({
    loginData: authReducer,
    data: dataReducer,
    common: commonReducer,
    notice: noticeReducer,
    product: productReducer,
    userPopup: userPopupRedux,
    productPopup: productPopupRedux,
    
    videoInfo: videoInfo,
    tagInfo: tagInfo,
    userInfo: userInfo
})

//To use with "Redux DevTools" chrome extension 
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


export default function generateStore(){

    const store = createStore(rootReducer, undefined, composeEnhancers(applyMiddleware(thunk)))
    return store;
}