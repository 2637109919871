import CommonTypes from "./CommonTypes"

const initialData = {
    error: null,
    isLoading: false
}

export default function commonReducer(state = initialData, action){

    switch(action.type){
      
        case CommonTypes.GET_DATA_ERROR:
            return {...state, list: null, error: action.payload}
        case CommonTypes.DATA_LOADING:
            return {...state, isLoading: action.payload}
        case CommonTypes.CLEAR_ERROR:
            return {...state, error: null}
        default:
            return state
    }
}