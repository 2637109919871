import React, { useEffect } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { Dialog, DialogContent } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const SystemErrorPanel = (props) => {
  const { t } = useTranslation();

  const error = useSelector((store) => store.common.error);
  const [openPanel, setOpenPanel] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(false);

  const handleCloseLoadingPanel = () => {
    setOpenPanel(false);
  };

  useEffect(() => {
    setOpenPanel(error !== null);
    setErrorMessage(error);
  }, [error]);

  return (
    <Dialog
      open={openPanel}
      onClose={handleCloseLoadingPanel}
      aria-labelledby="draggable-dialog-title"
      disableBackdropClick
    >
      <DialogContent>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpenPanel(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>
            <strong>{t("common.error")}</strong>
          </AlertTitle>
          {errorMessage}
        </Alert>

        <br />
      </DialogContent>
    </Dialog>
  );
};

export default SystemErrorPanel;
