import { InputLabel } from "@material-ui/core";
import React from "react";

const LabelCom = (props) => {
  const { text, ...rest } = props;

  return (
    <InputLabel
      style={{ textAlign: "left", height: "10px", fontWeight: "bold" }}
      {...rest}
    >
      {text}
    </InputLabel>
  );
};

export default LabelCom;
