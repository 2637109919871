import React from "react";
import { NavLink } from "react-router-dom";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  makeStyles,
  Typography,
} from "@material-ui/core";
import InboxIcon from "@material-ui/icons/Inbox";
import { useTranslation } from "react-i18next";

import VideoIcon from "@material-ui/icons/OndemandVideo";
import TagsIcon from "@material-ui/icons/LocalOffer";
import UserIcon from "@material-ui/icons/AccountBox";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  menuLink: {
    textDecoration: "none",
    color: theme.palette.text.primary,
  },
  active: {
    //backgroundColor: theme.palette.action.selected,
    color: "#ffab00",
    "& $icon": {
      color: "#ffab00",
    },
  },
  listItemText: {
    fontWeight: 600,
  },
  icon: {},
}));

const LeftMenu = (props) => {
  const isAdmin = useSelector((store) => store.loginData.isAdmin);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <List component="nav">
        <ListItem
          button
          component={NavLink}
          to={"/home"}
          onClick={props.onClose}
          activeClassName={classes.active}
        >
          <ListItemIcon className={classes.icon}>
            <VideoIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography className={classes.listItemText}>
                {t("leftMenu.top")}
              </Typography>
            }
          />
        </ListItem>

        {isAdmin && (
          <div>
            <Divider />
            <ListItem
              button
              component={NavLink}
              to={"/import"}
              onClick={props.onClose}
              activeClassName={classes.active}
            >
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary={"Import"} />
            </ListItem>

            <ListItem
              button
              component={NavLink}
              to={"/tags"}
              onClick={props.onClose}
              activeClassName={classes.active}
            >
              <ListItemIcon className={classes.icon}>
                <TagsIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography className={classes.listItemText}>Tags</Typography>
                }
              />
            </ListItem>

            <ListItem
              button
              component={NavLink}
              to={"/users"}
              onClick={props.onClose}
              activeClassName={classes.active}
            >
              <ListItemIcon className={classes.icon}>
                <UserIcon />
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography className={classes.listItemText}>
                    Users
                  </Typography>
                }
              />
            </ListItem>
          </div>
        )}
      </List>
    </div>
  );
};

export default LeftMenu;
