import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ButtonCom from "./ButtonCom";
import { Box, Grid } from "@material-ui/core";
import TextFieldCom from "./formik/TextFieldCom";
import ButtonPanel from "./ButtonPanel";

const validationSchema = Yup.object({
  input: Yup.string().trim().required("Required Input !!"),
});

const initialValues = {
  input: "",
};

const SingleTextFieldCom = (props) => {
  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          props.onSubmit(values.input);
          setSubmitting(false);
        }}
      >
        {(formikProps) => {
          return (
            <Form>
              <TextFieldCom
                name="input"
                label={props.label}
                tips={props.tips}
              />
              <Box style={{ padding: "10px" }}>
                <ButtonPanel>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <ButtonCom
                      text="Add"
                      buttontype="saveButton"
                      onClick={formikProps.handleSubmit}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <ButtonCom
                      text="Clear"
                      buttontype="backButton"
                      onClick={formikProps.handleReset}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <ButtonCom
                      text="Cancel"
                      buttontype="deleteButton"
                      onClick={props.onCancel}
                    />
                  </Grid>
                </ButtonPanel>
              </Box>
              <br />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default SingleTextFieldCom;
