import { createContext, useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/useAuthRedux";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const loginData = useSelector((state) => state.loginData);

  const initialUser = () => {
    try {
      return JSON.parse(localStorage.getItem("user"));
    } catch (e) {
        return null;
    }
  }

  const [user, setUser] = useState(initialUser);

  useEffect(() => {
    try {
      localStorage.setItem("user", JSON.stringify(user));
    } catch (error) {
      localStorage.removeItem("user");
      //console.log(error);
    }
  }, [user]);
  

  const contextValue = {
    user,
    login(userId, role, rememberPw){
      const loginUser = {userId, role, rememberPw};
      setUser(loginUser);
    },
    logout() {
      const tokenInfo = JSON.parse(localStorage.getItem("user"));
      const data = {token: tokenInfo.token, user: loginData?.loginUser?.user}
      dispatch(logout(data));

      localStorage.removeItem("user")
      setUser(null);
    },
    isLogged() {
      return user != null && loginData?.loginUser != null;
    },
    isChecking(){
      return user && !loginData.loginUser && !loginData.error;
    }
  };  

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthProvider;