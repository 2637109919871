import client from "./Client";

// Constants
const initialData = {
  list: [],
};

// Types
const MODULE_NAME = "TAG_";
const DATA_LIST = MODULE_NAME + "DATA_LIST";
const DATA_UPDATE = MODULE_NAME + "DATA_UPDATE";
const INITIAL_DATA = MODULE_NAME + "INITIAL_DATA";

// Reducer
export default function productReducer(state = initialData, action) {
  switch (action.type) {
    case DATA_LIST:
      return { ...state, list: action.payload };
    case DATA_UPDATE:
      return { ...state, list: action.payload[0] };
    case INITIAL_DATA:
      return initialData;
    default:
      return state;
  }
}

export const getTags = (userInfo) => async (dispatch, getState) => {
  let api = "M0303UserTagService.getUserTags";
  // Check user its Admin
  if (userInfo.role === "A") {
    api = "M0301TagService.getAllTags";
  }
  client.postData([{ api: api, input: userInfo }], dispatch).then((data) => {
    dispatch({
      type: DATA_LIST,
      payload: data[0].output,
    });
  });
};

export const getAllTags = () => async (dispatch, getState) => {
  client
    .postData([{ api: "M0301TagService.getAllTags" }], dispatch)
    .then((data) => {
      dispatch({
        type: DATA_LIST,
        payload: data[0].output,
      });
    });
};

export const addTag = (tagname) => async (dispatch, getState) => {
  client
    .postData([{ api: "M0301TagService.insertTag", input: tagname }], dispatch)
    .then((data) => {
      if (data[0].output[1].length !== 0)
        alert(
          `Tag "${data[0].output[1].map((value) => {
            return value;
          })}" are Exist !!`
        );
      dispatch({ type: DATA_UPDATE, payload: data[0].output });
    });
};

export const updateTag = (tagInfo) => async (dispatch, getState) => {
  client
    .postData([{ api: "M0301TagService.updateTag", input: tagInfo }], dispatch)
    .then((data) => {
      dispatch({
        type: DATA_LIST,
        payload: data[0].output,
      });
    });
};

export const removeTag = (tagid) => async (dispatch, getState) => {
  client
    .postData([{ api: "M0301TagService.removeTag", input: tagid }], dispatch)
    .then((data) => {
      dispatch({
        type: DATA_LIST,
        payload: data[0].output,
      });
    });
};

export const ClearTagState = () => (dispatch, getState) => {
  dispatch({
    type: INITIAL_DATA,
  });
};
