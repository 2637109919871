import React from "react";
import { Grid } from "@material-ui/core";
import PaperCom from "../../components/PaperCom";
import ButtonCom from "../../components/ButtonCom";

const M0201VideoChapterItem = (props) => {
  const formatTime = (value) => {
    return new Date(value * 1000).toISOString().substr(11, 8);
  };

  const deleteHandle = () => {
    props.onDeleteItem(props.item.time);
  };
  const seekTimeHandle = () => {
    props.onSeekTime(props.item.time);
  };

  return (
    <PaperCom
      p={1}
      m={1}
      style={{ backgroundColor: "#EEEEEE", cursor: "pointer" }}
      onClick={() => seekTimeHandle()}
    >
      <Grid container>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          {formatTime(props.item.time)} :
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          {props.item.content}
        </Grid>
        <Grid item xs={12} sm={12} md={2} lg={2}>
          <ButtonCom
            text="Delete"
            buttontype="deleteButton"
            onClick={() => deleteHandle()}
          />
        </Grid>
      </Grid>
    </PaperCom>
  );
};

export default M0201VideoChapterItem;
