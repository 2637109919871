import axios from "axios"
import SystemConsts from "../common/constants/SystemConsts"
import CommonTypes from "./CommonTypes"

const dataLoading = (data) => {
  return {
      type: CommonTypes.DATA_LOADING,
      payload: data,
  }
}

const error = (errorMessage) => {
  return {
      type: CommonTypes.GET_DATA_ERROR,
      payload: errorMessage,
  }
}

// const urlEncode = (obj) => {
//   if (obj) {
//     Object.keys(obj).forEach(key => {  
//       if (typeof obj[key] === 'object') {
//         urlEncode(obj[key])
//       } else {
//         obj[key] = encodeURIComponent(obj[key]).replace(/%0A/g, "%5Cn")
//       }
//     })
//   }
// }

const Client = {

  upload:(file, onUploadProgress,handleUploadFile) =>{
        let formData = new FormData();
        formData.append("file", file);
        // return axios.post(SystemConsts.ContextPath + SystemConsts.Api.UploadFileUrl, formData, {
        axios.post(SystemConsts.ContextPath + SystemConsts.Api.UploadFileUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        })
        .then((response) => {
          handleUploadFile(response.data, null);
        })
        .then((files) => {
            console.debug(JSON.stringify(files))
        })
        .catch((e) => {
          console.debug(e);
          handleUploadFile(null,e);
        });
  },
  download:(fileData) =>{
    let sendForm = document.createElement('form')
    sendForm.action = SystemConsts.ContextPath + SystemConsts.Api.DownloadUrl
    sendForm.method = 'POST'
    sendForm.style.visibility = 'hidden' // 画面に表示しない
    sendForm.target = 'download'
    document.body.appendChild(sendForm) // 画面内に追加

    let fld1 = document.createElement('input')
    fld1.name = 'data'
    fld1.type = 'hidden'
    fld1.value = JSON.stringify(fileData)
    sendForm.appendChild(fld1)
    sendForm.submit()

  },
  postData: (data, dispatch, preventShowLoading, preventShowError) => {

    let data2 = JSON.parse(JSON.stringify(data));
    
    let ctx = {
      data: data2
    };
    return new Promise((resolve, reject) => {
      dispatch(dataLoading(true));
      dispatch({type: CommonTypes.CLEAR_ERROR});
      // if(SystemConsts.debug){
      console.debug('■called:' + SystemConsts.Api.DataUrl + '\n' + JSON.stringify(ctx, null, 2))
      // }
      axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl, ctx)
      .then((response) => {
        
        // Check For Errors
        if (response.data.error) {
          dispatch({
            type: CommonTypes.GET_DATA_ERROR,
            payload: response.data.errorMessage || response.data.data[0].errors[0].messageKey
          })
          dispatch(dataLoading(false));
          return
        }

        // if(SystemConsts.debug){
        console.debug('■result:' + JSON.stringify(response.data, null, 2))
        // }
        resolve(response.data.data)
        // dispatch(dataLoading(false));
      })
      .catch((err ) => {
        console.debug('error:' + err.message);
        dispatch(error(err.message));
        reject(err)
      })
    })
    .finally(() => {
      dispatch(dataLoading(false));
    })
  },

  postApi: (data) => {

    let ctx = {
      data: data
    };
    return new Promise((resolve, reject) => {
      // if(SystemConsts.debug){
      console.debug('■called:' + SystemConsts.Api.DataUrl + '\n' + JSON.stringify(ctx, null, 2))
      // }
      axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
      .then((response) => {
       resolve(response.data.data)
      })
      .catch((err ) => {
        reject(err)
      })
    })
  },
  
  getData: (uri) => {
    // const dispatch = useDispatch();
    const url = window.API_URL + uri
    console.debug('■called getApi:' + url)
    return new Promise(resolve => {
      axios.get(url).then(response => {
        resolve(response.data.data[''].output)
      })
    })
  },
  
}
export default Client;