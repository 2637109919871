import React from "react";
import { Grid } from "@material-ui/core";
import PaperCom from "./PaperCom";
import EditForm from "../modules/parts/EditForm";
import * as Yup from "yup";
import { teal, orange } from "@material-ui/core/colors";
import TextFieldCom from "./formik/TextFieldCom";
import SelectCom from "./formik/SelectCom";
import DateTimePickerCom from "./formik/DateTimePickerCom";
import Moment from "moment";
import { useDispatch } from "react-redux";
import { removeVideo, updateVideo } from "../redux/getVideoRedux";

const validationSchema = Yup.object({
  title: Yup.string().required("Please Enter Title !"),
  date: Yup.date().required("Please Select Date !"),
  tag: Yup.string().required("Please Select Tag !"),
});

const VideoDetailCom = (props) => {
  const dispatch = useDispatch();
  const initialValues = {
    title: props.videoInfo.title,
    description: props.videoInfo.description,
    date: Moment.utc(props.videoInfo.date).local().format("YYYY-MM-DD HH:mm"),
  };

  const initTags = props.initTags.map((value) => {
    return { value: value.id, label: value.name };
  });

  const handleSave = (formikProps) => {
    const videoUpdatedInfo = {
      ...formikProps,
      date: Moment(formikProps.date).utc().format("YYYY-MM-DD HH:mm"),
      id: props.videoInfo.id,
      is_pending: props.is_pending ?? false,
    };
    dispatch(
      updateVideo({ ...videoUpdatedInfo, updateList: props.updateList ?? null })
    );
    if (props.onClose) props.onClose();
  };

  const handleDelete = (formikProps) => {
    if (window.confirm(`Do you confirm delete "${formikProps.title}" ?`)) {
      dispatch(
        removeVideo({
          ...props.videoInfo,
          is_pending: props.is_pending ?? false,
          updateList: props.updateList ?? null,
        })
      );
      if (props.onClose) props.onClose();
    }
  };

  const handleWatch = () => {
    window.open(
      `https://www.youtube.com/watch?v=${props.videoInfo.info}`,
      "_blank"
    );
  };

  const handleEdit = () => {
    window.open(
      `https://studio.youtube.com/video/${props.videoInfo.info}/edit`,
      "_blank"
    );
  };

  const WatchButton = {
    text: "Watch on YouTube",
    customcolor: teal,
    onClick: handleWatch,
  };

  const EditButton = {
    text: "Edit on YouTube",
    customcolor: orange,
    onClick: handleEdit,
  };

  return (
    <PaperCom m={1} p={1}>
      <EditForm
        initialValues={initialValues}
        validationSchema={validationSchema}
        handleSave={handleSave}
        handleDelete={handleDelete}
        hideClearButton
        buttons={[WatchButton, EditButton]}
      >
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <img
            src={`https://i.ytimg.com/vi/${props.videoInfo.info}/hqdefault.jpg`}
            alt={props.info}
            height="150px"
            width="100%"
          />
          {props.videoInfo.tag_name && <b>Tag:&ensp;{props.videoInfo.tag_name}</b>}
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <TextFieldCom name="title" label="Title" multiline />
          <TextFieldCom name="description" label="Description" multiline />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <DateTimePickerCom label="Date" name="date" />
          <SelectCom label="Tag" name="tag" listitems={initTags} />
        </Grid>
      </EditForm>
    </PaperCom>
  );
};

export default VideoDetailCom;
