import React from "react";
import { Grid } from "@material-ui/core";
import ButtonCom from "./ButtonCom";

const ListPageCom = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
      <Grid item xs={3} sm={3} md={3} lg={3}>
        {props.showPrev && (
          <ButtonCom
            text={"Previous"}
            buttontype="clearButton"
            onClick={() => props.onChange("prev")}
          />
        )}
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: "center" }}>
        <b>{props.pageLabel}</b>
      </Grid>
      <Grid item xs={3} sm={3} md={3} lg={3}>
        {props.showNext && (
          <ButtonCom
            text={"Next"}
            buttontype="clearButton"
            onClick={() => props.onChange("next")}
          />
        )}
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={2}></Grid>
    </Grid>
  );
};

export default ListPageCom;
