import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSharingVideo, ClearVideoState } from "../../redux/getVideoRedux";
import PaperCom from "../../components/PaperCom";
import VideoSharePlay from "./M0201VideoSharePlay";

export default function M0201VideoShare(props) {
  const dispatch = useDispatch();
  const { videoId } = useParams();
  const getVideoInfo = useSelector((state) => state.videoInfo.list);
  const [videoInfo, setVideoInfo] = useState(false);

  useEffect(() => {
    dispatch(getSharingVideo(videoId));
    return () => {
      dispatch(ClearVideoState());
    };
  }, [dispatch, videoId]);

  useEffect(() => {
    setVideoInfo(getVideoInfo);
    return () => {
      setVideoInfo(false);
    };
  }, [getVideoInfo]);

  let display = (
    <div style={{ textAlign: "center" }}>
      <b>Loading...</b>
    </div>
  );
  if (videoInfo.data)
    display = <VideoSharePlay videoInfo={videoInfo.data[0]} />;
  if (videoInfo.error)
    display = (
      <div style={{ textAlign: "center" }}>
        <b>Video Not Found !!</b>
      </div>
    );

  return (
    <div
      style={{
        position: "fixed",
        backgroundColor: "beige",
        overflowY: "auto",
        height: "100%",
        width: "100%",
      }}
    >
      <PaperCom m={5}>{display}</PaperCom>
    </div>
  );
}
