import { Box, TextField } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import LabelCom from "../LabelCom";
import { Field } from "formik";

const TextFieldCom = (props) => {
  const { tips, label, ...rest } = props;

  let style = props.style;
  if (props.disabled) {
    if (!props.style) {
      style = { backgroundColor: "#eeeeee" };
    }
  }

  return (
    <Box m={1}>
      <LabelCom
        style={{ textAlign: "left", height: "20px", fontWeight: "bold" }}
        text={label}
      />

      <Field name={props.name}>
        {({
          field, // { name, value, onChange, onBlur }
          form, //: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => (
          <TextField
            size="small"
            helperText={meta.error}
            error={Boolean(meta.error)}
            margin="dense"
            variant="outlined"
            fullWidth
            style={style}
            autoComplete={props.autoComplete ? props.autoComplete : "off"}
            {...rest}
            {...field}
            onChange={(e) => {
              form.handleChange(e);
              if (props.onChange) {
                props.onChange(e.target.value);
              }
            }}
          />
        )}
      </Field>
      
      <LabelCom
        style={{ textAlign: "left", height: "10px", fontSize: "12px", color: "green" }}
        text={tips}
      />
    </Box>
  );
};

TextFieldCom.propTypes = {
  name: PropTypes.string.isRequired,
  //handleChange: PropTypes.func.isRequired,
  //handleBlur: PropTypes.func.isRequired,
};

export default TextFieldCom;
