import {Switch} from "react-router-dom";
import NotFound from "../pages/notFound/NotFound";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ErrorPage from "../pages/error/ErrorPage";

// Component
import Home from "../pages/M0201Video/M0201Video";
import Import from "../pages/M0201VideoImport/M0201VideoImport";
import Tags from "../pages/M0301Tag/M0301Tag";
import Users from "../pages/M0101User/M0101User";

export default function ContainerRouter(props) {
  return (
      <Switch>
        <PrivateRoute exact path="/" component={Home} />

        <PrivateRoute exact path="/home" component={Home} />
        <PrivateRoute exact path="/import" component={Import} />
        <PrivateRoute exact path="/tags" component={Tags} />
        <PrivateRoute exact path="/users" component={Users} />

        <PublicRoute  path="/error" component={ErrorPage} />
        <PrivateRoute path="*" component={NotFound} />
      
      </Switch>
  );
}