import client from "./Client";

// Constants
const initialData = {
  list: [],
  pendingList: [],
};

// Types
const MODULE_NAME = "VIDEO_";
const DATA_LIST = MODULE_NAME + "DATA_LIST";
const DATA_PENDING_LIST = MODULE_NAME + "DATA_PENDING_LIST";
const DATA_INSERT = MODULE_NAME + "DATA_INSERT";
const INITIAL_DATA = MODULE_NAME + "INITIAL_DATA";

// Reducer
export default function productReducer(state = initialData, action) {
  switch (action.type) {
    case DATA_LIST:
      return { ...state, list: action.payload };
    case DATA_PENDING_LIST:
      return { ...state, pendingList: action.payload };
    case DATA_INSERT:
      return {
        ...state,
        pendingList: action.payload[0],
      };
    case INITIAL_DATA:
      return initialData;
    default:
      return state;
  }
}

export const getVideo = (videoInfo) => async (dispatch, getState) => {
  client
    .postData(
      [{ api: "M0201VideoService.getVideo", input: videoInfo }],
      dispatch
    )
    .then((data) => {
      dispatch({
        type: DATA_LIST,
        payload: data[0].output,
      });
    });
};

export const getPendingVideo = () => async (dispatch, getState) => {
  client
    .postData([{ api: "M0201VideoService.getPendingVideo" }], dispatch)
    .then((data) => {
      dispatch({
        type: DATA_PENDING_LIST,
        payload: data[0].output,
      });
    });
};

export const getSharingVideo = (videoId) => async (dispatch, getState) => {
  client
    .postData(
      [{ api: "M0201VideoService.getSharingVideo", input: videoId }],
      dispatch
    )
    .then((data) => {
      dispatch({
        type: DATA_LIST,
        payload: data[0].output,
      });
    });
};

export const addYTVideo = (videoInfo) => async (dispatch, getState) => {
  client
    .postData(
      [{ api: "M0201VideoService.addYTVideo", input: videoInfo }],
      dispatch
    )
    .then((data) => {
      if (data[0].output[1])
        alert(
          `Have ${data[0].output[1].exist} exist video and ${data[0].output[1].error} video cannot found !!`
        );
      dispatch({
        type: DATA_INSERT,
        payload: data[0].output,
      });
    });
};

export const updateVideo = (videoInfo) => async (dispatch, getState) => {
  client
    .postData(
      [{ api: "M0302VideoTagService.updateVideoTag", input: videoInfo }],
      dispatch
    )
    .then(() => {
      client
        .postData(
          [{ api: "M0201VideoService.updateVideo", input: videoInfo }],
          dispatch
        )
        .then((data) => {
          if (videoInfo.is_pending) {
            dispatch({
              type: DATA_PENDING_LIST,
              payload: data[0].output,
            });
          } else {
            dispatch({
              type: DATA_LIST,
              payload: data[0].output,
            });
          }
        });
    });
};

export const removeVideo = (videoInfo) => async (dispatch, getState) => {
  client
    .postData(
      [{ api: "M0201VideoService.removeVideo", input: videoInfo }],
      dispatch
    )
    .then((data) => {
      if (videoInfo.is_pending) {
        dispatch({
          type: DATA_PENDING_LIST,
          payload: data[0].output,
        });
      } else {
        dispatch({
          type: DATA_LIST,
          payload: data[0].output,
        });
      }
    });
};

export const ClearVideoState = () => async (dispatch, getState) => {
  dispatch({
    type: INITIAL_DATA,
  });
};
