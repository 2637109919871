import React, { useState } from "react";
import { useDispatch } from "react-redux";
import UserDetail from "./M0101UserDetail";
import { Grid, Modal } from "@material-ui/core";
import PaperCom from "../../components/PaperCom";
import ButtonCom from "../../components/ButtonCom";
import { updateUserTag as updateUserTagRedux } from "../../redux/getUserRedux";
import TagMultiSelectCom from "../../components/TagMultiSelectCom";

const M0101UserList = (props) => {
  const dispatch = useDispatch();
  // init user's tag
  const initUserTagName = props.userInfo.tag_id.map((value) => value.name);

  const [isEditUser, setIsEditUser] = useState(false);
  const [isEditTag, setIsEditTag] = useState(false);
  const [updateUserTag, setUpdateUserTag] = useState(props.userInfo.tag_id);

  const editUserToggle = () => setIsEditUser((state) => !state);
  const editTagToggle = () => {
    if (isEditTag) setUpdateUserTag(props.userInfo.tag_id);
    setIsEditTag((state) => !state);
  };
  const userTagHandle = (userTags) => setUpdateUserTag(userTags);
  const submitUserTagHandle = () => {
    dispatch(
      updateUserTagRedux({
        ...props.userInfo,
        searchData: props.searchData,
        tag_id: updateUserTag,
      })
    );
    editTagToggle();
  };

  return (
    <div>
      {isEditTag && (
        <Modal
          open={isEditTag}
          style={{ overflow: "auto", margin: "5em" }}
          onClose={editTagToggle}
        >
          <PaperCom m={6} p={6}>
            <h3>Tags: </h3>
            <TagMultiSelectCom
              onChange={(e) => userTagHandle(e)}
              initData={props.initTag}
              initUserTag={initUserTagName}
            />
            <br />
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <ButtonCom
                  text="Update"
                  buttontype="saveButton"
                  onClick={submitUserTagHandle}
                />
              </Grid>
              <Grid item xs={3} sm={3} md={3} lg={3}>
                <ButtonCom
                  text="Cancel"
                  buttontype="backButton"
                  onClick={editTagToggle}
                />
              </Grid>
            </Grid>
          </PaperCom>
        </Modal>
      )}
      {isEditUser && (
        <Modal
          open={isEditUser}
          style={{ overflow: "auto", margin: "5em" }}
          onClose={editUserToggle}
        >
          <PaperCom m={2} p={2}>
            <UserDetail
              onCancel={editUserToggle}
              searchData={props.searchData}
              userInfo={props.userInfo}
            />
          </PaperCom>
        </Modal>
      )}
      <PaperCom m={2} p={2} style={{ backgroundColor: "#EEEEEE" }}>
        <Grid container spacing={1}>
          <Grid item xs={2} sm={2} md={1} lg={1}>
            ID: {props.userInfo.id}
          </Grid>
          <Grid item xs={4} sm={4} md={2} lg={2}>
            {props.userInfo.role === "A" ? "Admin" : "User"}
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            {props.userInfo.username}
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            {props.userInfo.role !== "A" && (
              <ButtonCom
                text="Tags"
                buttontype="backButton"
                onClick={editTagToggle}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <ButtonCom
              text="Edit"
              buttontype="clearButton"
              onClick={editUserToggle}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <ButtonCom
              text="Delete"
              buttontype="deleteButton"
              onClick={props.onDelete}
            />
          </Grid>
        </Grid>
      </PaperCom>
    </div>
  );
};

export default M0101UserList;
