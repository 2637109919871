import React from "react";
import { Grid } from "@material-ui/core";
import PaperCom from "../../components/PaperCom";
import Moment from 'moment';

const M0201VideoList = (props) => {
  return (
    <PaperCom
      m={4}
      p={3}
      style={{ backgroundColor: "#EEEEEE", cursor: "pointer" }}
      onClick={props.onClick}
    >
      <Grid container spacing={0}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <img
            src={`https://i.ytimg.com/vi/${props.videoInfo.info}/maxresdefault.jpg`}
            alt={props.info}
            width="100%"
          />
          <br />
          <b>Tag:&ensp;{props.videoInfo.tag_name}</b>
        </Grid>
        <Grid item xs={12} sm={12} md={1} lg={1}></Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <p>
            <b>Title: </b>
            {props.videoInfo.title}
          </p>
          <p>
            <b>Date: </b>
            {Moment.utc(props.videoInfo.date).local().format('YYYY-MM-DD HH:mm')}
          </p>
          <p style={{whiteSpace:"pre-line"}}>
            <b>Description: </b>
            {props.videoInfo.description || "-"}
          </p>
        </Grid>
      </Grid>
    </PaperCom>
  );
};

export default M0201VideoList;
