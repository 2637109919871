import { Grid } from "@material-ui/core";
import VideoPlayerYTCom from "../../components/VideoPlayerYTCom";

export default function M0201VideoSharePlay(props) {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <VideoPlayerYTCom
            videoId={props.videoInfo.info}
            title={props.videoInfo.title}
            initSelect={props.videoInfo.chapter}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <h2>{props.videoInfo.title}</h2>
          <p>Date: {props.videoInfo.date}</p>
          <p>Description: {props.videoInfo.description}</p>
        </Grid>
      </Grid>
    </>
  );
}
