import React, { useState } from "react";
import { TextField, Box, Grid } from "@material-ui/core";
import DateRangePicker from "@mui/lab/DateRangePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Moment from "moment";
import EditForm from "../../modules/parts/EditForm";
import TextFieldCom from "../../components/formik/TextFieldCom";
import SelectCom from "../../components/formik/SelectCom";
import LabelCom from "../../components/LabelCom";

const initSearch = {
  tagId: "",
  title: "",
};

const VideoSearchCom = (props) => {
  const [dateRangePicker, setdateRangePicker] = useState([null, null]);

  const initTags = props.initTags.map((value) => {
    return { value: value.id, label: value.name };
  });

  const searchHandle = (formikProps) => {
    let searchData = {};
    if (formikProps.tagId !== "")
      searchData = { ...searchData, tagId: [formikProps.tagId] };
    if (formikProps.title !== "")
      searchData = { ...searchData, title: formikProps.title };
    if (dateRangePicker[0] !== null && dateRangePicker[0] !== null)
      searchData = {
        ...searchData,
        dateRange: [
          Moment(dateRangePicker[0]).utc().format("YYYY-MM-DD HH:mm:ss"),
          Moment(dateRangePicker[1])
            .add(1, "d")
            .utc()
            .format("YYYY-MM-DD HH:mm:ss"),
        ],
      };
    props.onSearch(searchData);
  };

  const searchResetHandle = () => {
    setdateRangePicker([null, null]);
  };

  return (
    <Box p={1}>
      <EditForm
        initialValues={initSearch}
        handleSave={searchHandle}
        handleReset={searchResetHandle}
        submitLabel={"search"}
        hideDeleteButton
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <TextFieldCom name="title" label="Title" multiline />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3}>
            <SelectCom label="Tag" name="tagId" listitems={initTags} />
          </Grid>
          <Grid item xs={12} sm={12} md={5} lg={5}>
            <Box p={1}>
              <LabelCom
                style={{
                  textAlign: "left",
                  height: "17px",
                  fontWeight: "bold",
                }}
                text={"Date Range"}
              />
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateRangePicker
                  calendars={1}
                  startText="Start Date"
                  endText="End Date"
                  value={dateRangePicker}
                  onChange={(dateRangePicker) => {
                    setdateRangePicker([
                      dateRangePicker[0] &&
                        Moment(dateRangePicker[0]).format("YYYY-MM-DD"),
                      dateRangePicker[1] &&
                        Moment(dateRangePicker[1]).format("YYYY-MM-DD"),
                    ]);
                  }}
                  renderInput={(startProps, endProps) => (
                    <React.Fragment>
                      <TextField {...startProps} />
                      <Box sx={{ mx: 2 }}> to </Box>
                      <TextField {...endProps} />
                    </React.Fragment>
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
        </Grid>
      </EditForm>
    </Box>
  );
};

export default VideoSearchCom;
