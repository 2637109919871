import React, { useState, useEffect } from "react";
import { Box, Button, Grid } from "@material-ui/core";
import VideoPlayerYTCom from "../../components/VideoPlayerYTCom";
import ButtonCom from "../../components/ButtonCom";
import PaperCom from "../../components/PaperCom";
import VideoDetailCom from "../../components/VideoDetailCom";
import { Modal } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateVideo } from "../../redux/getVideoRedux";
import VideoChapter from "../M0201VideoChapter/M0201VideoChapter";

const PlayVideo = (props) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [isEditChapter, setIsEditChapter] = useState(false);
  const [videoInfo, setVideoInfo] = useState("");

  useEffect(() => {
    setVideoInfo(props.videoInfo);
  }, [props.videoInfo]);

  const editHandle = () => setIsEdit((state) => !state);
  const editChapterHandle = () => setIsEditChapter((state) => !state);

  const updateVideoInfo = (updateInfo) => {
    let isChapterUpdate = false;
    let updatedVideoInfo = {
      ...videoInfo,
      updateList: props.updateList ?? null,
      tag: videoInfo.tag_id,
    };
    if (updateInfo === "updateStatus") {
      updatedVideoInfo = {
        ...updatedVideoInfo,
        is_enable: !videoInfo.is_enable,
        is_sharing: 0,
      };
    } else if (updateInfo === "updateShare") {
      if (!videoInfo.is_enable) return alert("Please enable video first !!");
      updatedVideoInfo = {
        ...updatedVideoInfo,
        is_sharing: videoInfo.is_sharing === 1 ? 0 : 1,
      };
    } else {
      updatedVideoInfo = { ...updatedVideoInfo, chapter: updateInfo };
      setVideoInfo({
        ...updatedVideoInfo,
        chapter: JSON.stringify(updateInfo),
      });
      isChapterUpdate = true;
    }
    if (!isChapterUpdate) setVideoInfo(updatedVideoInfo);
    setIsEdit(false);
    setIsEditChapter(false);
    dispatch(updateVideo(updatedVideoInfo));
  };

  let videoPlayInfo = <p>Video Not Found</p>;
  if (videoInfo !== null) {
    videoPlayInfo = (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5} lg={5}>
          <VideoPlayerYTCom
            videoId={videoInfo.info}
            title={videoInfo.title}
            initSelect={videoInfo.chapter}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7}>
          <h2>{videoInfo.title}</h2>
          <p>Date: {videoInfo.date}</p>
          <p style={{ whiteSpace: "pre-line" }}>
            Description: {videoInfo.description || "-"}
          </p>
        </Grid>
      </Grid>
    );
  }
  return (
    <Box p={1}>
      {videoInfo && (
        <PaperCom>
          {isEdit && (
            <Modal
              open={isEdit}
              style={{ overflow: "auto", margin: "5em" }}
              onClose={editHandle}
            >
              <VideoDetailCom
                key={videoInfo.info}
                initTags={props.initTags}
                videoInfo={videoInfo}
                onClose={() => {
                  if (props.onCancel) props.onCancel();
                  editHandle();
                }}
                updateList={props.updateList}
              />
            </Modal>
          )}

          {isEditChapter && (
            <VideoChapter
              initData={videoInfo.chapter}
              videoId={videoInfo.info}
              onClose={editChapterHandle}
              onSubmit={(items) => updateVideoInfo(items)}
            />
          )}

          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <ButtonCom
                text="CLOSE"
                buttontype="backButton"
                onClick={props.onCancel}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}></Grid>
            {props.isAdmin && (
              <>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <ButtonCom
                    text="Chapter"
                    buttontype="clearButton"
                    onClick={editChapterHandle}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <ButtonCom
                    text={videoInfo.is_sharing === 1 ? "Unshare" : "Share"}
                    buttontype="saveButton"
                    onClick={() => updateVideoInfo("updateShare")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <ButtonCom
                    text={videoInfo.is_enable ? "Disable " : " Enable"}
                    buttontype="addButton"
                    onClick={() => updateVideoInfo("updateStatus")}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                  <ButtonCom
                    text="EDIT"
                    buttontype="searchButton"
                    onClick={editHandle}
                  />
                </Grid>
              </>
            )}
            {videoInfo.is_sharing === 1 && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <br />
                <PaperCom
                  p={1}
                  style={{
                    backgroundColor: "#8DFF8D",
                    textAlign: "center",
                    borderStyle: "dotted",
                  }}
                >
                  <b>
                    Video is sharing at :&ensp;
                    <a
                      href={window.origin + "/share/" + videoInfo.info}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {window.origin + "/share/" + videoInfo.info}
                    </a>
                  </b>
                  &emsp;
                  <Button
                    style={{ backgroundColor: "#E0E0E0" }}
                    variant="contained"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        window.origin + "/share/" + videoInfo.info
                      );
                      alert("Link Copied !");
                    }}
                  >
                    Copy
                  </Button>
                </PaperCom>
              </Grid>
            )}
            {!videoInfo.is_enable && (
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <br />
                <PaperCom
                  p={1}
                  style={{
                    backgroundColor: "#EEEEEE",
                    textAlign: "center",
                    borderStyle: "dotted",
                  }}
                >
                  <b>This video is disabled</b>
                </PaperCom>
              </Grid>
            )}
          </Grid>
          <br />
          {videoPlayInfo}
        </PaperCom>
      )}
    </Box>
  );
};

export default PlayVideo;
