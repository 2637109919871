import axios from "axios";
import SystemConsts from "../common/constants/SystemConsts";

//constants
const initialData = {
  loginUser: null,
  error: null,
  isAdmin: false,
};

//types
const GET_LOGIN_USER_SUCCESS = "GET_LOGIN_USER_SUCCESS";
const GET_LOGIN_USER_FAIL = "GET_LOGIN_USER_FAIL";
const GET_LOGOUT_USER_SUCCESS = "GET_LOGOUT_USER_SUCCESS";
const GET_LOGOUT_USER_FAIL = "GET_LOGOUT_USER_FAIL";
const GET_ERR_500 = "GET_ERR_500";
const REFRESH_LOGIN_USER = "REFRESH_LOGIN_USER";

//reducer
export default function authReducer(state = initialData, action) {
  switch (action.type) {
    case GET_LOGIN_USER_SUCCESS:
      return {
        ...state,
        loginUser: action.payload,
        isAdmin: action.payload.role === "A" ? true : false,
      };
    case GET_LOGIN_USER_FAIL:
      return { loginUser: null, error: action.payload, isAdmin: false };
    case GET_LOGOUT_USER_SUCCESS:
      return { loginUser: null, error: null, isAdmin: false };
    case GET_LOGOUT_USER_FAIL:
      return { loginUser: null, error: action.payload, isAdmin: false };
    case GET_ERR_500:
      return { loginUser: null, error: action.payload, isAdmin: false };
    case REFRESH_LOGIN_USER:
      let refreshUser = state.loginUser;
      refreshUser.user = action.payload;
      refreshUser.role = action.payload.role === "A" ? true : false;
      return { ...state, loginUser: refreshUser };
    default:
      return state;
  }
}

//actions
export const login =
  (values, setErrorHandler, setLoginUser) => async (dispatch, getState) => {
    var ctx = {
      data: [
        {
          api: "AuthService.userLogin",
          mode: 0,
          input: {
            username: values.username,
            password: values.user_password,
          },
        },
      ],
    };

    await axios
      .post(SystemConsts.ContextPath + SystemConsts.Api.AuthUrl, ctx)
      .then((response) => {
        if (response.data.data[0].output?.id) {
          dispatch({
            type: GET_LOGIN_USER_SUCCESS,
            payload: response.data.data[0].output,
          });
          setLoginUser(response.data.data[0].output, values);
        } else {
          let dispatchError = "";
          if (response.data.error) {
            dispatchError = response.data.errorMessage;
            setErrorHandler({
              hasError: true,
              incorrectUser: false,
              message: dispatchError,
            });
          } else {
            dispatchError = "login.error.incorrect";
            setErrorHandler({
              hasError: true,
              incorrectUser: true,
              message: dispatchError,
            });
          }
          dispatch({
            type: GET_LOGIN_USER_FAIL,
            payload: dispatchError,
          });
        }
      })
      .catch((err) => {
        //Error
        console.log("Axios err: ", err);
        setErrorHandler({
          hasError: true,
          incorrectUser: false,
          message: err.message,
        });
        dispatch({
          type: GET_ERR_500,
          payload: err.message,
        });
      });
  };

export const checkSession =
  (userId, setErrorHandler) => async (dispatch, getState) => {
    var ctx = {
      data: [
        {
          api: "AuthService.getLoginUser",
          mode: 0,
          input: {
            id: userId,
          },
        },
      ],
    };

    await axios
      .post(SystemConsts.ContextPath + SystemConsts.Api.AuthUrl, ctx)
      .then((response) => {
        if (response.data.data[0].output?.id) {
          dispatch({
            type: GET_LOGIN_USER_SUCCESS,
            payload: response.data.data[0].output,
          });
        } else {
          localStorage.removeItem("user");
          setErrorHandler({
            hasError: true,
            incorrectUser: true,
            message: "login.error.timeout",
          });
          dispatch({
            type: GET_LOGIN_USER_FAIL,
            payload: "incorrect token",
          });
        }
      })
      .catch((err) => {
        //Error
        setErrorHandler({
          hasError: true,
          incorrectUser: false,
          message: err.message,
        });
        console.log("Axios err: ", err);
        dispatch({
          type: GET_ERR_500,
          payload: err.message,
        });
      });
  };

export const logout = (user) => async (dispatch, getState) => {
  var ctx = {
    data: [
      {
        api: "AuthService.logout",
        mode: 0,
      },
    ],
  };

  await axios
    .post(SystemConsts.ContextPath + SystemConsts.Api.AuthUrl, ctx)
    .then((response) => {
      if (!response.data.error) {
        dispatch({
          type: GET_LOGOUT_USER_SUCCESS,
        });
      } else {
        dispatch({
          type: GET_LOGOUT_USER_FAIL,
          payload: response.data.errorMessage,
        });
      }
    })
    .catch((err) => {
      //Error
      console.log("Axios err: ", err);
      dispatch({
        type: GET_LOGOUT_USER_FAIL,
        payload: err.message,
      });
    })
    .finally(() => {
      //history.push("/");
      //Redirect("/");
    });
};

export const RefreshLoginUserData = (loginUser) => (dispatch, getState) => {
  dispatch({
    type: REFRESH_LOGIN_USER,
    payload: loginUser,
  });
};
