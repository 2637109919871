import React from "react";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";
import {
  cyan,
  green,
  indigo,
  orange,
  purple,
  red,
} from "@material-ui/core/colors";

const ButtonCom = (props) => {
  const { ...rest } = props;

  //Custom button example:
  /*
  <ButtonCom
    text="test"
    buttontype="custombutton"
    customcolor={red}
    onClick={props.handleNew}
  />
  */

  const useStyles = props.customcolor
    ? makeStyles((theme) => ({
        custombutton: {
          color: theme.palette.getContrastText(props.customcolor[500]),
          backgroundColor: props.customcolor[500],
          "&:hover": {
            backgroundColor: props.customcolor[700],
          },
        },
      }))
    : makeStyles((theme) => ({
        addButton: {
          color: theme.palette.getContrastText(purple[300]),
          backgroundColor: purple[300],
          "&:hover": {
            backgroundColor: purple[500],
          },
        },
        saveButton: {
          color: theme.palette.getContrastText(green[600]),
          backgroundColor: green[600],
          "&:hover": {
            backgroundColor: green[500],
          },
        },
        clearButton: {
          color: theme.palette.getContrastText(cyan[700]),
          backgroundColor: cyan[700],
          "&:hover": {
            backgroundColor: cyan[500],
          },
        },
        searchButton: {
          color: theme.palette.getContrastText(indigo[400]),
          backgroundColor: indigo[400],
          "&:hover": {
            backgroundColor: indigo[600],
          },
        },
        backButton: {
          color: theme.palette.getContrastText(orange[800]),
          backgroundColor: orange[800],
          "&:hover": {
            backgroundColor: orange[900],
          },
        },
        deleteButton: {
          color: theme.palette.getContrastText(red[400]),
          backgroundColor: red[400],
          "&:hover": {
            backgroundColor: red[600],
          },
        },
      }));

  const classes = useStyles();

  return (
    <Button
      {...rest}
      variant="contained"
      fullWidth
      //className={props.bgcolor ? classes.color : ""}
      className={classes[props.buttontype]}
      style={props.style}
    >
      {props.text}
      {props.children}
    </Button>
  );
};

ButtonCom.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ButtonCom;
