import React, { useState } from "react";
import ButtonCom from "../../components/ButtonCom";
import {
  Box,
  Grid,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@material-ui/core";

const initSearch = {
  username: "",
  role: "",
};

const M0101UserSearch = (props) => {
  const [searchData, setSearchData] = useState(initSearch);

  const searchSubmitHandle = () => {
    props.onSearch(searchData);
  };
  const searchResetHandle = () => {
    setSearchData(initSearch);
    props.onSearch(initSearch);
  };

  return (
    <Box p={1}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            label="Username :"
            value={searchData.username}
            onChange={(e) =>
              setSearchData((state) => {
                return { ...state, username: e.target.value };
              })
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <FormControl fullWidth variant="standard">
            <InputLabel id="role">Type :</InputLabel>
            <Select
              label="role"
              value={searchData.role}
              onChange={(e) =>
                setSearchData((state) => {
                  return { ...state, role: e.target.value };
                })
              }
            >
              <MenuItem value={"A"}>Admin</MenuItem>
              <MenuItem value={"U"}>User</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box display="flex">
            <Box p={1}>
              <ButtonCom
                text="Search"
                buttontype="searchButton"
                onClick={searchSubmitHandle}
              />
            </Box>
            <Box p={1}>
              <ButtonCom
                text="Reset"
                buttontype="backButton"
                onClick={searchResetHandle}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default M0101UserSearch;
