import React from "react";
import { useSelector } from "react-redux";

export default function ErrorPage(props) {
  const loginData = useSelector((state) => state.loginData);

  const errorMessage = loginData?.error
    ? loginData.error.message
    : props.message;

  return (
    <>
      <div style={{ color: "red" }}>ERROR: {errorMessage}</div>
    </>
  );
}
