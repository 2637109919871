const SystemConsts = {
	ContextPath: process.env.REACT_APP_CONTEXT_PATH,
	Api:{
		Host: window.location.origin, 
		ApiEndPoint: 'http://localhost:8000',
		AuthUrl : 'auth.api',
		DataUrl : 'data.api',
		UploadFileUrl: 'upload.api',
		DownloadUrl: 'download.api'
    },
	Video :{
		AppId: '58479'	
	},
	Debug : true,
};

export default SystemConsts
