import { TextField } from "@material-ui/core";
import { Component } from "react";
import ButtonCom from "../../components/ButtonCom";

let loadYT;

export default class M0201VideoGetChapter extends Component {
  componentDidMount() {
    if (!loadYT) {
      loadYT = new Promise((resolve) => {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        window.onYouTubeIframeAPIReady = () => resolve(window.YT);
      });
    }
    loadYT.then((YT) => {
      this.player = new YT.Player(this.youtubePlayerAnchor, {
        height: this.props.height || "250px",
        width: this.props.width || "100%",
        videoId: this.props.YTid,
        events: {
          onStateChange: this.onPlayerStateChange,
        },
      });
    });
  }
  constructor(props) {
    super(props);
    this.state = { content: "" };
  }

  onSeekTime = (time) => this.player.seekTo(time);

  onContentChange = (value) => {
    this.setState({ content: value });
  };

  onPlayerStateChange = (e) => {
    if (typeof this.props.onStateChange === "function") {
      this.props.onStateChange(e);
    }
  };

  onSubmitHandle = () => {
    if (this.state.content === "") return;

    this.props.onClick({
      time: parseInt(this.player.getCurrentTime().toFixed(0)),
      content: this.state.content,
    });
    this.setState({ content: "" });
  };

  render() {
    return (
      <section>
        <div
          ref={(r) => {
            this.youtubePlayerAnchor = r;
          }}
        ></div>
        <TextField
          style={{ marginTop: "1em" }}
          label="Content"
          variant="outlined"
          fullWidth
          multiline
          value={this.state.content}
          onChange={(e) => this.onContentChange(e.target.value)}
        />
        <ButtonCom
          style={{ marginTop: "1em", marginBottom: "1em" }}
          text="Add"
          buttontype="clearButton"
          onClick={this.onSubmitHandle}
        />
      </section>
    );
  }
}
