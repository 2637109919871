import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Formik, Form } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import TextFieldCom from "../../components/formik/TextFieldCom";
import ButtonCom from "../../components/ButtonCom";
import useAuth from "../../auth/useAuth";
import { checkSession } from "../../redux/useAuthRedux";
import { useDispatch, useSelector } from "react-redux"; // hooks react redux
import { login } from "../../redux/useAuthRedux"; // import the action
import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Login() {
  // Locale use
  const { t } = useTranslation();

  const loginData = useSelector((state) => state.loginData); //Login data from Redux
  const classes = useStyles(); //Style
  const dispatch = useDispatch(); //Redux dispatcher
  const auth = useAuth(); //Auth provider

  //Error hook
  const [errorHandler, setErrorHandler] = useState({
    hasError: false,
    message: "",
    incorrectUser: false,
  });

  //Set the Auth provider's login
  const setLoginUser = (user, values) => {
    auth.login(user.username, user.role, values.savelogin);
  };

  //Login action
  const handleLogin = async (values, setSubmitting) => {
    dispatch(login(values, setErrorHandler, setLoginUser));
    setSubmitting(false);
  };

  //auto-login
  useEffect(() => {
    const user = auth.user;
    if (user && !errorHandler.hasError) {
      if (!loginData?.loginUser) {
        dispatch(checkSession(user.id, setErrorHandler));
      }
    }
  }, [loginData, dispatch, auth.user, errorHandler.hasError]);
  //end auto-login

  if (auth.isChecking()) {
    return (
      <Typography component="div">
        <Box fontWeight="fontWeightRegular" m={3}>
          { t("container.loading") }
          <CircularProgress size={25} />
        </Box>
      </Typography>
    );
  }

  return (
    <Formik
      validateOnBlur={false}
      validateOnChange={false}
      initialValues={{ username: "", user_password: "", savelogin: "1" }}
      validationSchema={Yup.object({
        username: Yup.string().required(t("error.required", {value: t("login.username")})),
        user_password: Yup.string().required(t("error.required", {value: t("login.password")})),
      })}
      onSubmit={(values, { setSubmitting }) => {
        handleLogin(values, setSubmitting);
      }}
    >
      {(props) => {
        return (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>

              <Form className={classes.form}>
                <TextFieldCom name="username" label={t("login.username")} autoFocus />
                <TextFieldCom
                  name="user_password"
                  type="password"
                  label={t("login.password")}
                  autoComplete="current-password"
                />

                {(errorHandler.hasError || errorHandler.incorrectUser) && (
                  <h4 style={{ color: "red" }}>{t(errorHandler.message)}</h4>
                )}

                <ButtonCom
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  text={t("login.login")}
                />
              </Form>
            </div>
          </Container>
        );
      }}
    </Formik>
  );
}
