import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getVideo, ClearVideoState } from "../../redux/getVideoRedux";
import { getTags, ClearTagState } from "../../redux/getTagRedux";
import { Box, Divider } from "@material-ui/core";
import PaperCom from "../../components/PaperCom";
import PlayVideo from "./M0201VideoPlay";
import VideoList from "./M0201VideoList";
import VideoSearchCom from "./M0201VideoSearch";
import ListPageCom from "../../components/ListPageCom";

const M0201Video = (props) => {
  const dispatch = useDispatch();
  const list = useSelector((store) => store.videoInfo.list);
  const tagListStore = useSelector((store) => store.tagInfo.list);
  const userInfo = useSelector((store) => store.loginData.loginUser);
  const isAdmin = useSelector((store) => store.loginData.isAdmin);

  const initSearch = useMemo(() => {
    return {
      tagId: [],
      page: 1,
      isAdmin: isAdmin,
      userId: userInfo.id,
    };
  }, [isAdmin, userInfo]);

  const [isPlayVideo, setIsPlayVideo] = useState(null);
  const [videoLists, setVideoLists] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [searchData, setSearchData] = useState(initSearch);

  useEffect(() => {
    if (tagListStore.length !== 0) {
      if (!isAdmin) {
        dispatch(
          getVideo({
            ...initSearch,
            tagId: tagListStore.map((value) => value.id),
          })
        );
      } else if (isAdmin) {
        dispatch(getVideo(initSearch));
      }
    }

    setTagList(tagListStore);
    return () => {
      dispatch(ClearVideoState());
    };
  }, [dispatch, initSearch, tagListStore, isAdmin]);

  useEffect(() => {
    dispatch(getTags(userInfo));
    return () => {
      dispatch(ClearTagState());
    };
  }, [dispatch, userInfo]);

  useEffect(() => {
    setVideoLists(list);
  }, [list]);

  const startPlayHandle = (props) => {
    setIsPlayVideo(props);
    window.scrollTo(0, 0);
  };

  const cancelPlayHandle = () => {
    setIsPlayVideo(null);
  };

  const searchSubmitHandle = (props) => {
    let tag = [];
    if (!isAdmin) {
      tag = tagListStore.map((tag) => tag.id);
    }

    setSearchData({
      ...initSearch,
      tagId: tag,
      ...props,
    });
    dispatch(
      getVideo({
        ...initSearch,
        tagId: tag,
        ...props,
      })
    );
  };

  const pageChangeHandle = (props) => {
    let page = searchData.page;
    if (props === "prev") {
      --page;
    }
    if (props === "next") {
      ++page;
    }
    setSearchData({ ...searchData, page: page });
    dispatch(getVideo({ ...searchData, page: page }));
  };

  let displayVideoList = (
    <div style={{ textAlign: "center" }}>
      <p>
        <b>Currently No Videos here</b>
      </p>
    </div>
  );

  if (videoLists.length > 0)
    displayVideoList = videoLists.map((value) => (
      <VideoList
        key={value.info}
        videoInfo={value}
        onClick={() => startPlayHandle(value)}
      />
    ));

  return (
    <div>
      <PlayVideo
        videoInfo={isPlayVideo}
        onCancel={cancelPlayHandle}
        isAdmin={isAdmin}
        initTags={tagList}
        updateList={searchData}
      />
      <Box p={1}>
        <PaperCom>
          <VideoSearchCom onSearch={searchSubmitHandle} initTags={tagList} />
          <Divider />
          {displayVideoList}
          <br />
          {(videoLists.length !== 0 || searchData.page > 1) && (
            <ListPageCom
              pageLabel={searchData.page}
              showPrev={searchData.page > 1}
              showNext={videoLists.length === 10}
              onChange={pageChangeHandle}
            />
          )}
        </PaperCom>
      </Box>
    </div>
  );
};

export default M0201Video;
