import { Dialog, DialogContent, DialogContentText } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LoadingPanel = (props) => {
  const { t } = useTranslation();
  const isLoading = useSelector((store) => store.common.isLoading);
  const [openLoadingPanel, setOpenLoadingPanel] = React.useState(false);

  const handleCloseLoadingPanel = () => {
    setOpenLoadingPanel(false);
  };

  useEffect(() => {
    setOpenLoadingPanel(isLoading);
  }, [isLoading]);

  return (
    <Dialog
      open={openLoadingPanel}
      onClose={handleCloseLoadingPanel}
      aria-labelledby="draggable-dialog-title"
      disableBackdropClick
    >
      <DialogContent>
        <DialogContentText>{t("container.loading")}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingPanel;
