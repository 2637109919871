import client from '../Client'

// Constants
const initialData = {
    list: [],
    row: {},
    seriesList: [],
    searchCond: {}
}


// Types
const MODULE_NAME = "PRODUCT_"
const DATA_LIST =  MODULE_NAME + "DATA_LIST"
const DATA_ROW = MODULE_NAME + "DATA_ROW"
const SERIES_LIST = MODULE_NAME + "SERIES_LIST"
const INITIAL_DATA = MODULE_NAME + "INITIAL_DATA"
const SEARCH_COND = MODULE_NAME + "SEARCH_COND"


// Reducer
export default function productReducer(state = initialData, action){

    switch(action.type){
        case DATA_LIST:
            return {...state, list: action.payload, row: {}, error: null}
        case DATA_ROW:
            return {...state, row: action.payload, error: null}
        case SERIES_LIST:
            return {...state, seriesList: action.payload}
        case INITIAL_DATA:
            return initialData
        case SEARCH_COND:
            return {...state, searchCond: action.payload}
        default:
            return state
    }
}


// Actions
export const init = () => async (dispatch, getState) => {
    dispatch({type: DATA_LIST, payload: []})
    client.postData([{api: "m0040SeriesService.findSeries"}], dispatch)
        .then((data) => {
            dispatch({
                type: SERIES_LIST,
                payload: data[0].output
            })
        }
    )
} 

export const search = (searchCond) => async (dispatch, getState) => {
    dispatch({
        type: SEARCH_COND,
        payload: searchCond
    })
    client.postData([{api: "m0020ProductService.search", input: searchCond }], dispatch)
        .then((data) => {
            dispatch({
                type: DATA_LIST,
                payload: data[0].output
            })
        }
    )
} 

export const getDetail = (id) => async (dispatch, getState) => {
    client.postData([{api: "m0020ProductService.getDetail", input: {product_id : id} }], dispatch)
        .then((data) => {
            dispatch({
                type: DATA_ROW,
                payload: data[0].output
            })
        }
    )
} 

export const save = (product) => async (dispatch, getState) => {
    client.postData([{api: "m0020ProductService.save", input : product }], dispatch)
        .then(() => {
            client.postData([{api: "m0020ProductService.search", input : getState().product.searchCond}], dispatch)
                .then((data) => {
                    dispatch({
                        type: DATA_LIST,
                        payload: data[0].output
                    })
                })
        }
    )
} 

export const remove = (product) => async (dispatch, getState) => {
    client.postData([{api: "m0020ProductService.delete", input : product }], dispatch)
        .then(() => {
            client.postData([{api: "m0020ProductService.search", input : getState().product.searchCond}], dispatch)
                .then((data) => {
                    dispatch({
                        type: DATA_LIST,
                        payload: data[0].output
                    })
                })
        }
    )
}
