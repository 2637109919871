import React from "react";
import { Hidden, makeStyles } from "@material-ui/core";
import Header from "../header/Header";
import LeftDrawer from "../leftmenu/LeftDrawer";
import ContainerRouter from "../../routers/ContainerRouter";
import SystemErrorPanel from "./SystemErrorPanel";
import LoadingPanel from "./LoadingPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    //backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    //backgroundColor: "#e8eaf6",
  },
}));

const Container = () => {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const doOpenMenu = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <div
      className={classes.root}
      style={{ backgroundColor: "#f2f3f7", minHeight: "100vh" }}
    >
      <Header doOpenMenu={doOpenMenu} />

      <Hidden xsDown>
        <LeftDrawer variant="permanent" open={true} />
      </Hidden>

      <Hidden smUp>
        <LeftDrawer
          variant="temporary"
          open={openDrawer}
          onClose={doOpenMenu}
        />
      </Hidden>

      <div className={classes.content}>
        <div className={classes.toolbar}></div>
        <SystemErrorPanel />
        <LoadingPanel />
        <ContainerRouter />
      </div>
    </div>
  );
};

export default Container;
