import { Box } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import LabelCom from "../LabelCom";
import { Field } from "formik";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import format from "date-fns/format";
import enLocale from "date-fns/locale/en-US";

const DateTimePickerCom = (props) => {
  const { label } = props;

  let style = props.style;
  if (props.disabled) {
    if (!props.style) {
      style = { backgroundColor: "#eeeeee" };
    }
  }

  class ExtendedUtils extends DateFnsUtils {
    getCalendarHeaderText(date) {
      return format(date, "yyyy MMM", { locale: this.locale });
    }
    getDatePickerHeaderText(date) {
      return format(date, "MMMd日", { locale: this.locale });
    }
  }

  return (
    <Box m={1}>
      <LabelCom
        style={{ textAlign: "left", height: "25px", fontWeight: "bold" }}
        text={label}
      />

      <Field name={props.name}>
        {({
          field, // { name, value, onChange, onBlur }
          form, //: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => (
          <MuiPickersUtilsProvider locale={enLocale} utils={ExtendedUtils}>
            <KeyboardDateTimePicker
              size="small"
              ampm={false}
              inputVariant="outlined"
              format="yyyy/MM/dd HH:mm:ss"
              autoComplete="off"
              clearable={true}
              onChange={(date, value) => {
                form.setFieldValue(props.name, value, false);
                if (props.onChange) {
                  props.onChange(value);
                }
              }}
              value={field.value}
              error={Boolean(meta.error)}
              helperText={meta.error}
              style={style}
            />
          </MuiPickersUtilsProvider>
        )}
      </Field>
    </Box>
  );
};

DateTimePickerCom.propTypes = {
  name: PropTypes.string.isRequired,
  //handleChange: PropTypes.func.isRequired,
  //handleBlur: PropTypes.func.isRequired,
};

export default DateTimePickerCom;
