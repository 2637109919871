import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { Hidden, IconButton, Link, Menu, MenuItem, Button, makeStyles } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import ProfileButton from "./ProfileButton";
import LanguageIcon from "@material-ui/icons/Language";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  title: {
    flexGrow: 1,
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: "#050631",
  },
  logo: {
    width: theme.spacing(6),
    height: theme.spacing(6),
    paddingRight: '10px'
  },
}));

const languages = [
  {
    value: "en",
    label: "English",
  },
  {
    value: "ja",
    label: "日本語",
  }
];

const ITEM_HEIGHT = 48;

const Header = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [currentLanguage, setCurrentLanguage] = React.useState({
    value: "en",
    label: "English",
  });
  const { i18n } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseLang = () => {
    setAnchorEl(null);
  };

  const handleChangeLang = (lang) => {
    setCurrentLanguage(lang);
    i18n.changeLanguage(lang.value);
    setAnchorEl(null);
  };

  let history = useHistory();
  const title = "Video Recording Management";
  const titleSmall = "Video Record";
  return (
    <AppBar position="fixed" /*color="primary"*/ className={classes.appBar}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="menu"
          onClick={() => props.doOpenMenu()}
        >
          <MenuIcon />
        </IconButton>

        <Link
          onClick={() => {
            history.push("/home");
          }}
          style={{ cursor: "pointer" }}
        >
          <img className={classes.logo} alt="logo" src="https://files.ajobthing.com/employers/135298-1629863843.png" />
        </Link>

        <Hidden smDown>
          <Typography variant="h6" className={classes.title}>
            <Link
              onClick={() => {
                history.push("/home");
              }}
              style={{ cursor: "pointer" }}
              color="initial"
              underline="none"
            >
              {title}
            </Link>
          </Typography>
        </Hidden>

        <Hidden mdUp>
          <Typography variant="h6" className={classes.title}>
            <Link
              onClick={() => {
                history.push("/home");
              }}
              style={{ cursor: "pointer" }}
              color="initial"
              underline="none"
            >
              {titleSmall}
            </Link>
          </Typography>
        </Hidden>

        <Button
          variant="text"
          color="inherit"
          startIcon={<LanguageIcon />}
          onClick={handleClick}
        >
          <Hidden xsDown>{currentLanguage.label}</Hidden>
        </Button>

        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleCloseLang}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "20ch",
            },
          }}
        >
          {languages.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => handleChangeLang(option)}
            >
              {option.label}
            </MenuItem>
          ))}
        </Menu>

        <ProfileButton />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
