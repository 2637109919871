import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import japaneseLocale from './locales/ja/locale.json';
import englishLocale from './locales/en/locale.json';

// the translations
const resources = {
    ja: {
        translation: japaneseLocale
    },
    en: {
        translation: englishLocale
    },
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",
        fallbackLng: "en",
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            //wait: true,
            useSuspense: true,
        }
    });

export default i18n;
