import React, {
  Fragment,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import {
  Popover,
  AppBar,
  Divider,
  Typography,
  Box,
  Button,
  makeStyles,
  Hidden,
} from "@material-ui/core";
import PersonIcon from "@material-ui/icons/Person";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useAuth from "../../auth/useAuth";
//import { useDispatch } from "react-redux";
//import { logout } from "../../redux/useAuthRedux";
// import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    overflowY: "auto",
    maxHeight: 350,
  },
  popoverPaper: {
    width: "100%",
    maxWidth: 350,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      maxWidth: 270,
    },
  },
  divider: {
    marginTop: -2,
  },
  noShadow: {
    boxShadow: "none !important",
  },
}));

function ProfileButton(props) {
  const loginData = useSelector((state) => state.loginData);
  const anchorEl = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const auth = useAuth();
  //const dispatch = useDispatch();
  // let history = useHistory();

  const handleLogout = () => {
    auth.logout();
    //dispatch(logout(loginData?.loginUser?.user));
  };

  // const handleViewProfile = () => {
  //   setIsOpen(false);
  //   history.push("/profile");
  // };

  const handleClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);

  const handleClickAway = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const [loginUSer, setLoginUser] = useState(null);
  useEffect(() => {
    if (loginData && Object.keys(loginData).length > 0) {
      setLoginUser(loginData);
    }
  }, [loginData]);

  const id = isOpen ? "scroll-playground" : null;
  const classes = useStyles();

  return (
    <Fragment>
      <Button
        variant="text"
        color="inherit"
        onClick={handleClick}
        buttonRef={anchorEl}
        startIcon={<PersonIcon />}
      >
        <Hidden smDown>{loginUSer?.loginUser?.username}</Hidden>
      </Button>

      <Popover
        disableScrollLock
        id={id}
        open={isOpen}
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        classes={{ paper: classes.popoverPaper }}
        onClose={handleClickAway}
      >
        <AppBar position="static" color="inherit" className={classes.noShadow}>
          <Box pt={1} pl={2} pb={1} pr={1}>
            <Typography variant="subtitle1" align="center">
              {t("header.profile")}
            </Typography>
          </Box>
          <Divider className={classes.divider} />
          <Typography variant="subtitle1" align="center">
            <AccountCircleIcon style={{ fontSize: 100 }} />
          </Typography>
          <Typography variant="subtitle1" align="center">
            {loginUSer?.loginUser?.username}
          </Typography>
          <Box textAlign="center" p={2}>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => handleLogout()}
            >
              {t("header.logout")}
            </Button>
          </Box>
        </AppBar>
      </Popover>
    </Fragment>
  );
}

ProfileButton.propTypes = {
  //classes: PropTypes.object.isRequired,
  //messages: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ProfileButton;
