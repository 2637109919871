import React, { useState } from "react";
import {
  OutlinedInput,
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
  Box,
  Chip,
} from "@material-ui/core/";
import ButtonPanel from "./ButtonPanel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TagMultiSelectCom = (props) => {

  const classes = useStyles();
  const [tags, setTags] = useState(props.initUserTag);

  const submitHandle = (value) => {
    let result = [];
    for (const tagName of value) {
      for (const initName of props.initData) {
        if (tagName === initName.name) result.push(initName);
      }
    }
    props.onChange(result);
  };

  const tagChangeHandler = (event) => {
    const {
      target: { value },
    } = event;
    setTags(
      // On autofill we get a the stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    submitHandle(value);
  };

  const tagRemoveHandler = (e, props) => {
    e.preventDefault();
    setTags((state) => state.filter((name) => name !== props));
    submitHandle(tags.filter((name) => name !== props));
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="tags">Tag</InputLabel>
        <Select
          labelId="tags"
          id="tags"
          multiple
          value={tags}
          onChange={tagChangeHandler}
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {props.initData.map((tag) => (
            <MenuItem key={tag.name} value={tag.name}>
              <Checkbox checked={tags.indexOf(tag.name) > -1} />
              <ListItemText primary={tag.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ButtonPanel style={{ margin: "1px" }}>
        {tags.map((name) => (
          <Box m={1} key={name}>
            <Chip label={name} onDelete={(e) => tagRemoveHandler(e, name)} />
          </Box>
        ))}
      </ButtonPanel>
    </div>
  );
};

export default TagMultiSelectCom;
