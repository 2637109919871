import React, { useState } from "react";
import VideoChapterSelect from "../pages/M0201Video/M0201VideoChapterSelect";

const VideoPlayerYTCom = (props) => {
  const initSelect = JSON.parse(props.initSelect) ?? [];
  const [chapterSelect, setChapterSelect] = useState(null);

  const chapterSelectHandle = (props) => setChapterSelect(props);
  const getSrcUrl = () => {
    let url =
      "https://www.youtube.com/embed/" +
      props.videoId +
      (chapterSelect ? `?start=${chapterSelect}&autoplay=1` : "");
    console.debug("url=" + url);
    return url;
  };

  return (
    <div>
      <div style={{ padding: "50% 0 0 0", position: "relative" }}>
        <iframe
          src={getSrcUrl()}
          title={props.title ? props.title : "YouTube Embed Video"}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          allowFullScreen
        ></iframe>
      </div>
      {initSelect.length > 0 && (
        <VideoChapterSelect
          initData={initSelect}
          onSelect={chapterSelectHandle}
        />
      )}
    </div>
  );
};

export default VideoPlayerYTCom;
