import React, { useState, useEffect } from "react";
import UserList from "./M0101UserList";
import UserDetail from "./M0101UserDetail";
import UserSearch from "./M0101UserSearch";
import { Box, Divider } from "@material-ui/core";
import PaperCom from "../../components/PaperCom";
import ButtonCom from "../../components/ButtonCom";
import ListPageCom from "../../components/ListPageCom";
import { useDispatch, useSelector } from "react-redux";
import { getUser, removeUser, ClearUserState } from "../../redux/getUserRedux";
import { getTags, ClearTagState } from "../../redux/getTagRedux";

const initSearch = {
  username: "",
  role: "",
  page: 1,
};

const M0101User = (props) => {
  const dispatch = useDispatch();
  const [userAdding, setUserAdding] = useState(false);
  const [searchData, setSearchData] = useState(initSearch);
  const [userList, setUserList] = useState([]);
  const [tagList, setTagList] = useState([]);

  const list = useSelector((store) => store.userInfo.userList);
  const tagListStore = useSelector((store) => store.tagInfo.list);
  const userInfo = useSelector((store) => store.loginData.loginUser);

  useEffect(() => {
    dispatch(getUser(searchData));
    dispatch(getTags(userInfo));
    return () => {
      dispatch(ClearUserState());
      dispatch(ClearTagState());
    };
  }, [dispatch, searchData, userInfo]);

  useEffect(() => {
    setUserList(list);
  }, [list]);

  useEffect(()=>{
    setTagList(tagListStore);
  },[tagListStore])

  const userAddingToggle = () => setUserAdding((state) => !state);

  const searchSubmitHandle = (newSearchData) => {
    setSearchData((state) => {
      return { ...state, ...newSearchData };
    });
  };

  const userRemoveHandle = (props) => {
    if (window.confirm(`Do you confirm delete "${props.username}" account ?`)) {
      dispatch(removeUser({ searchData, ...props }));
    }
  };

  const pageChangeHandle = (props) => {
    let page = searchData.page;
    if (props === "prev") {
      --page;
    }
    if (props === "next") {
      ++page;
    }
    setSearchData({ ...searchData, page: page });
    dispatch(getUser({ ...searchData, page: page }));
  };

  return (
    <Box p={1}>
      <PaperCom>
        {userAdding && (
          <UserDetail onCancel={userAddingToggle} searchData={searchData} />
        )}
        {!userAdding && (
          <Box display="flex">
            <Box p={1}>
              <ButtonCom
                text="Add User"
                buttontype="saveButton"
                onClick={userAddingToggle}
              />
            </Box>
          </Box>
        )}
        <Divider />
        <UserSearch onSearch={searchSubmitHandle} />
        {userList.map((user) => (
          <UserList
            key={user.id}
            userInfo={user}
            searchData={searchData}
            onDelete={() => userRemoveHandle(user)}
            initTag={tagList}
          />
        ))}
        {(userList.length !== 0 || searchData.page > 1) && (
          <ListPageCom
            pageLabel={searchData.page}
            showPrev={searchData.page > 1}
            showNext={userList.length === 10}
            onChange={pageChangeHandle}
          />
        )}
      </PaperCom>
    </Box>
  );
};

export default M0101User;
