import { Box, MenuItem, TextField } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import LabelCom from "../LabelCom";
import { Field } from "formik";

const SelectCom = (props) => {
  const { label, ...rest } = props;

  if (props.firstitemempty === 1 && props.listitems) {
    if (!props.listitems.some((listItem) => listItem.value === "")) {
      props.listitems.unshift({ value: "", label: "　" });
    }
  }

  return (
    <Box m={1}>
      <LabelCom
        style={{ textAlign: "left", height: "10px", fontWeight: "bold" }}
        text={label}
      />

      <Field name={props.name}>
        {({
          field, // { name, value, onChange, onBlur }
          form, //: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
          meta,
        }) => (
          <TextField
            select
            size="small"
            helperText={meta.error}
            error={Boolean(meta.error)}
            margin="normal"
            variant="outlined"
            fullWidth
            //style={style}
            {...rest}
            {...field}
            onChange={(e) => {
              form.handleChange(e);
              if (props.onChange) {
                props.onChange(e.target.value);
              }
            }}
          >
            {props.listitems.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Field>
    </Box>
  );
};

SelectCom.propTypes = {
  name: PropTypes.string.isRequired,
  listitems: PropTypes.array.isRequired,
  //handleChange: PropTypes.func.isRequired,
  //handleBlur: PropTypes.func.isRequired,
};

export default SelectCom;
