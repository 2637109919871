import React from "react";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useDispatch } from "react-redux";
import { Box, Grid } from "@material-ui/core";
import { addUser, updateUser } from "../../redux/getUserRedux";
import ButtonCom from "../../components/ButtonCom";
import ButtonPanel from "../../components/ButtonPanel";
import SelectCom from "../../components/formik/SelectCom";
import TextFieldCom from "../../components/formik/TextFieldCom";

const validationSchema = Yup.object({
  username: Yup.string().trim().required("Please enter Username !!"),
  password: Yup.string().trim().required("Please enter Password !!"),
  role: Yup.string().required("Please select Role !!"),
});

const M0101UserDetail = (props) => {
  const dispatch = useDispatch();
  const initialValues = {
    username: props.userInfo ? props.userInfo.username : "",
    password: "",
    role: "",
  };
  const initRole = [
    { value: "A", label: "Admin" },
    { value: "U", label: "User" },
  ];

  const submitHandle = (formikProps) => {
    if (props.userInfo) {
      dispatch(
        updateUser({
          ...formikProps,
          searchData: props.searchData,
          userId: props.userInfo.id,
        })
      );
    } else {
      dispatch(addUser({ ...formikProps, searchData: props.searchData }));
    }
    props.onCancel();
  };

  return (
    <div>
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          submitHandle(values);
          setSubmitting(false);
        }}
      >
        {(formikProps) => {
          return (
            <Form>
              <Grid container>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextFieldCom name="username" label="Username" />
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <TextFieldCom name="password" label="Password" type="password"/>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <SelectCom label="Role" name="role" listitems={initRole} />
                </Grid>
              </Grid>
              <Box style={{ padding: "10px" }}>
                <ButtonPanel>
                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <ButtonCom
                      text={props.userInfo ? "Edit" : "Add"}
                      buttontype="saveButton"
                      onClick={formikProps.handleSubmit}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <ButtonCom
                      text="Clear"
                      buttontype="backButton"
                      onClick={formikProps.handleReset}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <ButtonCom
                      text="Cancel"
                      buttontype="deleteButton"
                      onClick={props.onCancel}
                    />
                  </Grid>
                </ButtonPanel>
              </Box>
              <br />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default M0101UserDetail;
