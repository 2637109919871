import { Grid } from "@material-ui/core";
import React from "react";

const ButtonPanel = (props) => {
  return (
    <Grid container spacing={2} {...props}>
      {props.children}
    </Grid>
  );
};

export default ButtonPanel;
