import client from "./Client";

// Constants
const initialData = {
  userList: [],
};

// Types
const MODULE_NAME = "USER_";
const DATA_LIST = MODULE_NAME + "DATA_LIST";
const INITIAL_DATA = MODULE_NAME + "INITIAL_DATA";

// Reducer
export default function productReducer(state = initialData, action) {
  switch (action.type) {
    case DATA_LIST:
      return { userList: action.payload };
    case INITIAL_DATA:
      return initialData;
    default:
      return state;
  }
}

export const getUser = (userInfo) => async (dispatch, getState) => {
  client
    .postData([{ api: "M0101UserService.getUserInfo", input: userInfo }], dispatch)
    .then((data) => {
      dispatch({
        type: DATA_LIST,
        payload: data[0].output,
      });
    });
};

export const addUser = (userInfo) => async (dispatch, getState) => {
  client
    .postData([{ api: "M0101UserService.addUser", input: userInfo }], dispatch)
    .then((data) => {
      if (data[0].output[1]) alert(`Username are exist !!`);

      dispatch({
        type: DATA_LIST,
        payload: data[0].output[0],
      });
    });
};

export const updateUser = (userInfo) => async (dispatch, getState) => {
  client
    .postData([{ api: "M0101UserService.updateUser", input: userInfo }], dispatch)
    .then((data) => {
      if (data[0].output[1]) alert(`Username are exist !!`);

      dispatch({
        type: DATA_LIST,
        payload: data[0].output[0],
      });
    });
};

export const updateUserTag = (userInfo) => async (dispatch, getState) => {
  client
    .postData([{ api: "M0101UserService.updateUserTag", input: userInfo }], dispatch)
    .then((data) => {
      dispatch({
        type: DATA_LIST,
        payload: data[0].output,
      });
    });
};

export const removeUser = (userInfo) => async (dispatch, getState) => {
  client
    .postData([{ api: "M0101UserService.deleteUser", input: userInfo }], dispatch)
    .then((data) => {
      dispatch({
        type: DATA_LIST,
        payload: data[0].output,
      });
    });
};

export const ClearUserState = () => async (dispatch, getState) => {
  dispatch({
    type: INITIAL_DATA,
  });
};
