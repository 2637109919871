import React, { useState } from "react";
import { Box, Grid, TextField } from "@material-ui/core";
import ButtonCom from "../../components/ButtonCom";

const M0301TagSearch = (props) => {
  const [searchTagName, setSearchTagName] = useState("");

  const searchChangeHandle = (e) => {
    setSearchTagName(e.target.value);
  };
  const searchSubmitHandle = () => {
    props.onSearch(searchTagName)
  };
  const searchResetHandle = () => {
    setSearchTagName("");
    props.onReset();
  };

  return (
    <Box p={1}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <TextField
            name="tagname"
            label="Search :"
            value={searchTagName}
            onChange={searchChangeHandle}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <Box display="flex">
            <Box p={1}>
              <ButtonCom
                text="Search"
                buttontype="searchButton"
                onClick={searchSubmitHandle}
              />
            </Box>
            <Box p={1}>
              <ButtonCom
                text="Reset"
                buttontype="backButton"
                onClick={searchResetHandle}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default M0301TagSearch;
