import axios from "axios"
import SystemConsts from "../common/constants/SystemConsts"
import CommonTypes from "./CommonTypes"

//constants
const initialData = {
    list: [],
    row: {},
}

//types
const GET_DATA_LIST = "GET_DATA_LIST"
const GET_DATA_ROW = "GET_DATA_ROW"
const SET_INITIAL_DATA = "SET_INITIAL_DATA"

//reducer
export default function dataReducer(state = initialData, action){

    switch(action.type){
        case GET_DATA_LIST:
            return {...state, list: action.payload, row: {}, error: null}
        case GET_DATA_ROW:
            return {...state, row: action.payload, error: null}
        case SET_INITIAL_DATA:
            return initialData
        default:
            return state
    }
}

//loading dispatcher
export const dataLoading = (data) => {
    return {
        type: CommonTypes.DATA_LOADING,
        payload: data,
    }
}

//actions
export const Search = (inputData, url, searchSingle, preventLoadingPanel) => async (dispatch, getState) => {

        var ctx = {
            data: [
              {
                api: url,
                mode: 0,
                input: inputData,
              },
            ],
          };

        if (searchSingle === null) searchSingle = false;
        if (preventLoadingPanel === null) preventLoadingPanel = false;

        
        if (!preventLoadingPanel){
            dispatch(dataLoading(true));

            //dispatch clear error
            dispatch({type: CommonTypes.CLEAR_ERROR});
        }

          await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
            .then((response) => {
                
                if (response.data.data[0].output){

                    if (!searchSingle){
                        dispatch({
                            type: GET_DATA_LIST,
                            payload: response.data.data[0].output
                        })
                    }
                    else{
                        if (Array.isArray(response.data.data[0].output)){
                            dispatch({
                                type: GET_DATA_ROW,
                                payload: response.data.data[0].output[0]
                            })
                        }
                    }
                }
                else{
                    dispatch({
                        type: CommonTypes.GET_DATA_ERROR,
                        payload: "System Error"//response.data.data[0].output
                    })
                }

            })
            .catch((err ) => {
                //Error
                console.log("Axios err: ", err)
                let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

                dispatch({
                    type: CommonTypes.GET_DATA_ERROR,
                    payload: errorMessage
                })
            })
            .finally(() => {
                if (!preventLoadingPanel){
                    dispatch(dataLoading(false));
                }
            });
        
} 

export const Save = (inputData, url, searchInputData, searchUrl, searchSingle, preventLoadingPanel) => async (dispatch, getState) => {

    let isLoading = true;
   
    var ctx = {
        data: [
          {
            api: url,
            mode: 0,
            input: inputData,
          },
        ],
      };

    if (searchSingle === null) searchSingle = false;

    if (!preventLoadingPanel){
        //dispatch loading state (true)
        dispatch(dataLoading(isLoading));

        //dispatch clear error
        dispatch({type: CommonTypes.CLEAR_ERROR});
    }
    

      await axios.post(SystemConsts.ContextPath + SystemConsts.Api.DataUrl,ctx)
        .then((response) => {
            
            if (response.data.data[0].output){
                
                //If we get the updated row, search for refresh the list
                if (searchUrl && searchInputData){
                    dispatch(Search(searchInputData, searchUrl, searchSingle, true));
                }
                else{
                    dispatch({
                        type: GET_DATA_ROW,
                        payload: response.data.data[0].output
                    })
                }
            }
            else{
                let errorMessage = "System Error";
                if (response.data.data[0].errors && response.data.data[0].errors[0].messageKey){
                    errorMessage = response.data.data[0].errors[0].messageKey;
                }

                dispatch({
                    type: CommonTypes.GET_DATA_ERROR,
                    payload: errorMessage
                })
            }

        })
        .catch((err ) => {
            //Error
            console.log("Axios err: ", err)
            let errorMessage = (err.response?.data?.message ? err.response.data.message:err.message)

            dispatch({
                type: CommonTypes.GET_DATA_ERROR,
                payload: errorMessage
            })
        })
        .finally(() => {
            if (!preventLoadingPanel){
                isLoading = false;
                //dispatch loading state (false)
                dispatch(dataLoading(isLoading));
            }
        });
    
} 

export const ClearState = () => (dispatch, getState) => {
    dispatch({
        type: SET_INITIAL_DATA
      });
}