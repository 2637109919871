import React from "react";
import { makeStyles, Drawer } from "@material-ui/core";
import LeftMenu from "./LeftMenu";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    //backgroundColor: "#37474f",
    //color: "white",
  },
  toolbar: theme.mixins.toolbar,
}));

const LeftDrawer = (props) => {
  const classes = useStyles();
  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
      variant={props.variant}
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
      //style={{ borderRight: "1px solid rgba(0, 0, 0, 0.12" }}
    >
      <div
        className={classes.toolbar}
        style={{ backgroundColor: "#050631" /*, borderRight: "0px"*/ }}
      />

      <LeftMenu onClose={props.onClose ? props.onClose : null} />
    </Drawer>
  );
};

export default LeftDrawer;
