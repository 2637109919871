import React from "react";
import ChapterItem from "./M0201VideoChapterItem";

const M0201VideoChapterList = (props) => {
  return (
    <div>
      {props.items.map((chapter) => (
        <ChapterItem
          key={chapter.time}
          id={chapter.time}
          item={chapter}
          onDeleteItem={(itemId) => props.onDelete(itemId)}
          onSeekTime={(time) => props.onSeekTime(time)}
        />
      ))}
    </div>
  );
};

export default M0201VideoChapterList;
