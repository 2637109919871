import React from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import appTheme from "./appTheme";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AuthProvider from "./auth/AuthProvider";
import Login from "./pages/login/Login";
import Container from "./modules/container/Container";
import PrivateRoute from "./routers/PrivateRoute";
import PublicRoute from "./routers/PublicRoute";
import { Provider } from "react-redux";
import generateStore from "./redux/store";
import ErrorPage from "./pages/error/ErrorPage";
import VideoShare from "./pages/M0201VideoShare/M0201VideoShare";
//import CheckLogin from "./pages/login/CheckLogin";

function App() {
  const store = generateStore();
  return (
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={appTheme}>
          <AuthProvider>
            <Switch>
              <Route exact path="/share/:videoId" component={VideoShare} />
              <PublicRoute exact path="/login" component={Login}></PublicRoute>
              <PublicRoute
                exact
                path="/error"
                component={ErrorPage}
              ></PublicRoute>
              <PrivateRoute exact path="/" component={Container}></PrivateRoute>
              <Container></Container>
            </Switch>
          </AuthProvider>
        </ThemeProvider>
      </Router>
    </Provider>
  );
}

export default App;
