import React, { useState } from "react";
import { InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";

const M0201VideoChapterSelect = (props) => {
  const [select, setSelect] = useState("");

  const handleChange = (event) => {
    setSelect(event.target.value);
    props.onSelect(event.target.value)
  };

  return (
    <FormControl fullWidth style={{ marginTop: "1em" }}>
      <InputLabel>&ensp;Chapter</InputLabel>
      <Select value={select} variant="outlined" onChange={handleChange} fullWidth>
        {props.initData.map((value) => (
          <MenuItem key={value.time} value={value.time}>
            {value.content}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default M0201VideoChapterSelect;
