import React from "react";
import { Formik, Form } from "formik";
import ButtonCom from "../../components/ButtonCom";
import { Box, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import ButtonPanel from "../../components/ButtonPanel";
import { useTranslation } from "react-i18next";

const EditForm = (formProps) => {
  const { t } = useTranslation();
  const handleBack = (e, handleReset) => {
    e.preventDefault();
    formProps.setListItem({});
  };

  const handleDelete = (e, values) => {
    e.preventDefault();
    formProps.handleDelete(values);
  };

  return (
    <Box p={2}>
      <Formik
        enableReinitialize={formProps.enableReinitialize ? true : false}
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={formProps.initialValues}
        validationSchema={formProps.validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            formProps.handleSave(values);
            setSubmitting(false);
          }, 400);
        }}
        onReset={() => {
          if (formProps.handleReset) formProps.handleReset();
        }}
      >
        {(props) => {
          return (
            <>
              <Form>
                <Grid container spacing={1}>
                  {formProps.children}
                </Grid>
                <br />
                <ButtonPanel>
                  {!formProps.hideSaveButton && <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                    <ButtonCom
                      text={formProps.submitLabel ?? t("common.save")}
                      buttontype="saveButton"
                      onClick={props.handleSubmit}
                    />
                  </Grid>}

                  {!formProps.hideClearButton && (
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <ButtonCom
                        text={formProps.clearLabel ?? t("common.clear")}
                        buttontype="clearButton"
                        onClick={props.handleReset}
                      />
                    </Grid>
                  )}

                  {!formProps.hideDeleteButton && (
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <ButtonCom
                        text={formProps.deleteLabel ?? t("common.delete")}
                        buttontype="deleteButton"
                        onClick={(e) => handleDelete(e, props.values)}
                      />
                    </Grid>
                  )}

                  {/* <Divider orientation="vertical" flexItem /> */}

                  {formProps.buttons &&
                    formProps.buttons.map(
                      (button) =>
                        (button.visible == null || button.visible === true) && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={2}
                            lg={2}
                            xl={2}
                            key={button.text}
                          >
                            <ButtonCom
                              text={button.text}
                              key={button.text}
                              buttontype="custombutton"
                              customcolor={button.customcolor}
                              onClick={(e) => {
                                if (button.triggerValidation) {
                                  props.validateForm().then((errors) => {
                                    if (Object.keys(errors).length === 0) {
                                      e.preventDefault();
                                      button.onClick(props.values);
                                    }
                                  });
                                } else {
                                  e.preventDefault();
                                  button.onClick(props.values);
                                }
                              }}
                            />
                          </Grid>
                        )
                    )}

                  {formProps.BackButton && (
                    <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                      <ButtonCom
                        text={t("common.back")}
                        buttontype="backButton"
                        onClick={(e) => handleBack(e)}
                      />
                    </Grid>
                  )}
                </ButtonPanel>
              </Form>
            </>
          );
        }}
      </Formik>
    </Box>
  );
};

EditForm.propTypes = {
  handleSave: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default EditForm;
