import React, { useState, useEffect } from "react";
import { Box, Divider } from "@material-ui/core";
import PaperCom from "../../components/PaperCom";
import SingleTextFieldCom from "../../components/SingleTextFieldCom";
import ButtonCom from "../../components/ButtonCom";
import { useSelector, useDispatch } from "react-redux";
import {
  addYTVideo,
  getPendingVideo,
  ClearVideoState,
} from "../../redux/getVideoRedux";
import {
  getAllTags,
  ClearTagState,
} from "../../redux/getTagRedux";
import VideoDetailCom from "../../components/VideoDetailCom";

const M0201VideoImport = (props) => {
  const dispatch = useDispatch();
  const [YTLinkAdding, setYTLinkAdding] = useState(false);

  // Get Pending Video
  const videoInfo = useSelector((store) => store.videoInfo.pendingList);
  const TagsList = useSelector((store) => store.tagInfo.list);

  const [videoList, setVideoList] = useState([]);

  useEffect(() => {
    dispatch(getPendingVideo());
    dispatch(getAllTags());
    return () => {
      dispatch(ClearVideoState());
      dispatch(ClearTagState());
    };
  }, [dispatch]);

  useEffect(() => {
    setVideoList(videoInfo);
  }, [videoInfo]);

  const YTLinkAddingToggle = () => {
    setYTLinkAdding((state) => !state);
  };

  const YTLinkSubmitHandler = (props) => {
    dispatch(addYTVideo(props));
    YTLinkAddingToggle();
  };

  return (
    <Box>
      <PaperCom>
        {YTLinkAdding && (
          <SingleTextFieldCom
            onSubmit={YTLinkSubmitHandler}
            onCancel={YTLinkAddingToggle}
            label={"YouTube Link:"}
            tips={"* Separate with comma (,)"}
          />
        )}
        {!YTLinkAdding && (
          <Box display="flex">
            <Box p={1}>
              <ButtonCom
                text="Import from YouTube"
                buttontype="deleteButton"
                onClick={YTLinkAddingToggle}
              />
            </Box>
          </Box>
        )}
        <Divider />
        <Box>
          {videoList.map((video) => {
            return (
              <VideoDetailCom
                key={video.id}
                initTags={TagsList}
                videoInfo={video}
                is_pending
              />
            );
          })}
        </Box>
      </PaperCom>
    </Box>
  );
};

export default M0201VideoImport;
