import React, { useState, useRef } from "react";
import VideoChapterGet from "./M0201VideoChapterGet";
import { Grid, Modal, Divider } from "@material-ui/core";
import PaperCom from "../../components/PaperCom";
import ChapterList from "./M0201VideoChapterList";
import ButtonCom from "../../components/ButtonCom";

const M0201VideoChapter = (props) => {
  const [chapter, setChapter] = useState(JSON.parse(props.initData ?? "[]"));
  const seekVideoTime = useRef();

  const addChapterHandle = (value) => {
    setChapter((prevChapterList) => {
      let updatedChapterList = prevChapterList.filter(
        (item) => item.time !== value.time
      );
      updatedChapterList.push({ time: value.time, content: value.content });
      updatedChapterList.sort(function (a, b) {
        return a.time - b.time;
      });
      return updatedChapterList;
    });
  };

  const deleteChapterHandle = (itemId) => {
    setChapter((prevChapterList) => {
      return prevChapterList.filter((item) => item.time !== itemId);
    });
  };

  const seekTimeHandle = (time) => seekVideoTime.current.onSeekTime(time);
  const submitHandle = () => props.onSubmit(chapter);

  let displayChapterList = (
    <div style={{ textAlign: "center" }}>
      <p>
        <b>Currently No Video Chapter here</b>
      </p>
    </div>
  );

  if (chapter.length > 0) {
    displayChapterList = (
      <ChapterList
        items={chapter}
        onDelete={(itemId) => deleteChapterHandle(itemId)}
        onSeekTime={(time)=> seekTimeHandle(time)}
      />
    );
  }

  return (
    <Modal
      open={true}
      style={{ overflow: "auto", margin: "4em" }}
    >
      <PaperCom m={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <VideoChapterGet
              ref={seekVideoTime}
              YTid={props.videoId}
              onClick={addChapterHandle}
            />
            <Divider />
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <ButtonCom
                  style={{ marginTop: "1em" }}
                  text="Save"
                  buttontype="saveButton"
                  onClick={submitHandle}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6}>
                <ButtonCom
                  style={{ marginTop: "1em" }}
                  text="Cancel"
                  buttontype="backButton"
                  onClick={props.onClose}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            {displayChapterList}
          </Grid>
        </Grid>
      </PaperCom>
    </Modal>
  );
};

export default M0201VideoChapter;
