import client from '../Client'

// Constants
const initialData = {
    list: [],
    row: {},
    searchCond: {}
}


// Types
const MODULE_NAME = "NOTICE_"
const DATA_LIST =  MODULE_NAME + "DATA_LIST"
const DATA_ROW = MODULE_NAME + "DATA_ROW"
const INITIAL_DATA = MODULE_NAME + "INITIAL_DATA"
const SEARCH_COND = MODULE_NAME + "SEARCH_COND"


// Reducer
export default function noticeReducer(state = initialData, action){

    switch(action.type){
        case DATA_LIST:
            return {...state, list: action.payload, row: {}, error: null}
        case DATA_ROW:
            return {...state, row: action.payload, error: null}
        case INITIAL_DATA:
            return initialData
        case SEARCH_COND:
            return {...state, searchCond: action.payload}
        default:
            return state
    }
}


// Actions
export const init = () => async (dispatch, getState) => {
    dispatch({type: DATA_LIST, payload: []})
} 

export const search = (searchCond) => async (dispatch, getState) => {
    dispatch({
        type: SEARCH_COND,
        payload: searchCond
    })
    client.postData([{api: "T0040NoticeService.search", input: searchCond }], dispatch)
        .then((data) => {
            dispatch({
                type: DATA_LIST,
                payload: data[0].output
            })
        }
    )
} 

export const getDetail = (id) => async (dispatch, getState) => {
    client.postData([{api: "T0040NoticeService.getDetail", input: 1 }], dispatch)
        .then((data) => {
            dispatch({
                type: DATA_ROW,
                payload: data[0].output
            })
        }
    )
} 

export const save = (notice) => async (dispatch, getState) => {
    client.postData([{api: "T0040NoticeService.save", input : notice }], dispatch)
        .then(() => {
            client.postData([{api: "T0040NoticeService.search", input : getState().notice.searchCond}], dispatch)
                .then((data) => {
                    dispatch({
                        type: DATA_LIST,
                        payload: data[0].output
                    })
                })
        }
    )
} 

export const remove = (notice) => async (dispatch, getState) => {
    client.postData([{api: "T0040NoticeService.delete", input : notice }], dispatch)
        .then(() => {
            client.postData([{api: "T0040NoticeService.search", input : getState().notice.searchCond}], dispatch)
                .then((data) => {
                    dispatch({
                        type: DATA_LIST,
                        payload: data[0].output
                    })
                })
        }
    )
}
