import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Paper,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
} from "@material-ui/core";
import ButtonCom from "../../components/ButtonCom";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllTags,
  ClearTagState,
  addTag,
  updateTag,
  removeTag,
} from "../../redux/getTagRedux";
import EditIcon from "@material-ui/icons/Edit";
import ButtonPanel from "../../components/ButtonPanel";
import SingleTextFieldCom from "../../components/SingleTextFieldCom";
import TagSearch from "./M0301TagSearch";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5),
  },
}));

const M0301Tag = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [tagAdding, setTagAdding] = useState(false);
  const [tagEditingInfo, setTagEditingInfo] = useState(false);

  // Get Tags Data
  const TagsList = useSelector((store) => store.tagInfo.list);

  const [displayTagsList, setDisplayTagsList] = useState([]);

  useEffect(() => {
    dispatch(getAllTags());
    return () => {
      dispatch(ClearTagState());
    };
  }, [dispatch]);

  useEffect(() => {
    setDisplayTagsList(TagsList);
  }, [TagsList]);

  const tagAddingToggle = () => {
    setTagAdding((state) => !state);
  };

  const tagEditingOpen = (tag) => {
    setTagEditingInfo(tag);
  };

  const tagEditingClose = () => {
    setTagEditingInfo(false);
  };

  const tagUpdatedHandle = (tagName) => {
    setTagEditingInfo((state) => {
      return { ...state, name: tagName };
    });
  };

  const tagSubmitHandler = (props) => {
    dispatch(addTag(props));
    tagAddingToggle();
  };

  const tagSubmitUpdateHandler = () => {
    dispatch(updateTag(tagEditingInfo));
    tagEditingClose();
  };

  const tagRemoveHandler = () => {
    if (
      window.confirm(`Do you confirm delete "${tagEditingInfo.name}" tag ?`)
    ) {
      dispatch(removeTag(tagEditingInfo.id));
      tagEditingClose();
    }
  };

  const tagSearchHandler = (props) => {
    setDisplayTagsList(() =>
      TagsList.filter((tag) => tag.name.includes(props))
    );
  };

  const tagSearchResetHandler = () => {
    setDisplayTagsList(TagsList);
  };

  let displayList = (
    <div>
      <p>Currently No Tags here</p>
    </div>
  );

  if (displayTagsList.length > 0) {
    displayList = displayTagsList.map((tag) => (
      <Box key={tag.id} p={1}>
        <ButtonCom
          text={tag.name}
          onClick={() => tagEditingOpen(tag)}
          style={{ textTransform: "none" }}
        >
          &ensp;
          <EditIcon p={1} color="primary" />
        </ButtonCom>
      </Box>
    ));
  }

  return (
    <Box p={1}>
      <Paper className={classes.paper}>
        {tagAdding && (
          <SingleTextFieldCom
            onSubmit={tagSubmitHandler}
            onCancel={tagAddingToggle}
            label={"Tag Name:"}
            tips={"* Separate with comma (,)"}
          />
        )}
        {!tagAdding && (
          <Box display="flex">
            <Box p={1}>
              <ButtonCom
                text="Add Tag"
                buttontype="saveButton"
                onClick={tagAddingToggle}
              />
            </Box>
          </Box>
        )}
        <Divider />
        <TagSearch
          onSearch={tagSearchHandler}
          onReset={tagSearchResetHandler}
        />
        <h2>Tags:</h2>
        <Dialog
          open={tagEditingInfo !== false}
          onClose={tagEditingClose}
          fullWidth
        >
          <DialogContent>
            <TextField
              name="tagname"
              label="Tag Name: "
              value={tagEditingInfo.name}
              onChange={(e) => tagUpdatedHandle(e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
              multiline
            />
          </DialogContent>
          <DialogActions>
            <ButtonCom
              text="Update"
              buttontype="saveButton"
              onClick={tagSubmitUpdateHandler}
              
            />
            <ButtonCom
              text="Delete"
              buttontype="deleteButton"
              onClick={tagRemoveHandler}
            />
          </DialogActions>
        </Dialog>
        <Box p={1} display="flex">
          <ButtonPanel>{displayList}</ButtonPanel>
        </Box>
      </Paper>
    </Box>
  );
};

export default M0301Tag;
