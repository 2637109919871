import client from '../Client'
//constants
const initial = {
    searchCond:{
        user_type: "1",
    },
    list: [],
    row: {}
}

//types
const MODULE_NAME = "MS01_USER_POPUP_"
const LIST =  MODULE_NAME + "LIST"
const ROW = MODULE_NAME + "ROW"
const INITIAL = MODULE_NAME + "INITIAL"
const SEARCH_COND = MODULE_NAME + "SEARCH_COND"

//reducer
export default function onlineCourseReducer(state = initial, action){

    switch(action.type){
        case LIST:
            return {...state, list: action.payload, row: {}, error: null}
        case ROW:
            return {...state, row: action.payload, error: null}
        case SEARCH_COND:
            return {...state, searchCond: action.payload}
        case INITIAL:
            return initial
        default:
            return state
    }
}


//actions
export const Init = () => async (dispatch, getState) => {

} 

export const Search = (searchCond) => async (dispatch, getState) => {
    
    dispatch({
        type: SEARCH_COND,
        payload: searchCond
    })
    client.postData([{api: "T0010UsersService.search", input:searchCond }], dispatch)
        .then((data) =>{
            dispatch({
                type: LIST,
                payload: data[0].output
            })
        }
    )
}
export const ClearState = () => (dispatch, getState) => {
    dispatch({
        type: INITIAL
      });
}
